import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import "./styles.css";

class TokenInfo extends Component {

    render() {
        return (
            <Container fluid className="text-white">
                <Row className="mt-5">
                    <Col xs="12" className="text-center">
                        <h3>Ultra Low initial market cap!</h3>
                        <h3>Initial Market Cap at launch for PolkaStartedPad will be only $60,000!</h3>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs="12" md="6">
                        <Row className="justify-content-center">
                            <Col xs="11" >
                                <div className="TabContainer">
                                    <h5>Participate in IDO's</h5>
                                    <p>To participate in IDO’s, you must be staking PolkaStartedPad and have passed the one-time whitelist process at least 3 hours prior to the IDO time.</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs="12" md="6" className="mt-5 mt-md-0">
                        <Row className="justify-content-center">
                            <Col xs="11">
                                <div className="TabContainer">
                                    <h5>IDO and Token Information</h5>
                                    <Row>
                                        <Col>
                                            <p className="FieldHeaderStyle">
                                                Public Price
                                            </p>
                                            <p>
                                                $0.0040 USD
                                            </p>

                                        </Col>
                                        <Col>
                                            <p className="FieldHeaderStyle">
                                                Maximum Supply
                                            </p>
                                            <p>
                                                500,000,000
                                            </p>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6">
                                            <p className="FieldHeaderStyle">
                                                Supply at Listing
                                            </p>
                                            <p>
                                                15,000,000
                                            </p>

                                        </Col>
                                        <Col md="6">
                                            <p className="FieldHeaderStyle">
                                                Initial Market Cap
                                            </p>
                                            <p>
                                                $60,000 USD
                                            </p>

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <Row className="justify-content-center">
                            <Col xs="11">
                                <div className="LowerTabContainer">
                                    <h5>
                                        Tokenomics Percentages
                                    </h5>
                                    <Row>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Pre-seed
                                            </p>
                                            <p>
                                                8%
                                            </p>
                                        </Col>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Seed
                                            </p>
                                            <p>
                                                7%
                                            </p>
                                        </Col>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Public
                                            </p>
                                            <p>
                                                15%
                                            </p>
                                        </Col>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Liquidity
                                            </p>
                                            <p>
                                                15%
                                            </p>
                                        </Col>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Team
                                            </p>
                                            <p>
                                                10%
                                            </p>
                                        </Col>
                                    </Row>
                                    <br /><br />
                                    <Row>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Advisory
                                            </p>
                                            <p>
                                                8%
                                            </p>
                                        </Col>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Marketing
                                            </p>
                                            <p>
                                                12%
                                            </p>
                                        </Col>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Reserve
                                            </p>
                                            <p>
                                                5%
                                            </p>
                                        </Col>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Ecosystem
                                            </p>
                                            <p>
                                                5%
                                            </p>
                                        </Col>
                                        <Col md="2">
                                            <p className="FieldHeaderStyle">
                                                Referrals/Rewards
                                            </p>
                                            <p>
                                                15%
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default TokenInfo;

