import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import ApplyNow from "../ApplyNow";
import Home from "../Home";
import "./styles.css";


class HomeScreen extends Component {

    render() {
        return <Container fluid>
            <Row>
                <Col>
                    <Home />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ApplyNow />
                </Col>
            </Row>           
        </ Container>
    }
}
export default HomeScreen;

