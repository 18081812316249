import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toWei } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import HomeButton from "../HomeButton";
import { SingletonWeb3Instance } from "../../Web3";
import "./styles.css";

interface IProps {
    openModal: boolean;
    setOpenModal: Function
    rewardAmount: string
}

const WithdrawModal = (props: IProps) => {

    const [address, setAddress] = useState<string>("")
    const { account } = useWeb3React();
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const stakingContractPlaceholder = Web3Instance.stakingContractInstance

    const Withdraw = async () => {
        if (props.rewardAmount > '0') {
            await stakingContractPlaceholder.methods.withdrawRewardToAddress(address).send({ from: account, gas: 2000000 });
            props.setOpenModal(false);
        }
        else {
            alert("You have No rewards to collect")
            props.setOpenModal(false);

        }
    }
    return <Modal
        isOpen={props.openModal}
        centered={true}
        backdrop={true}
        size="sm"
    >
        <ModalHeader className="withdrawModalContainer">
            <div>
                {Translate("WithdrawModal.Heading")}
            </div>
        </ModalHeader>
        <ModalBody className="withdrawModalContainer">
            <div>
                {Translate("WithdrawModal.Content")}
                <Input
                    placeholder={Translate("WithdrawModal.Placeholder")}
                    value={address}
                    onChange={(event: any) => setAddress(event.target.value)}
                />
            </div>
        </ModalBody>
        <ModalFooter className="withdrawModalContainer">
            <HomeButton title={Translate("WithdrawModal.WithdrawToAcocunt")}
                onClick={() => {
                    Withdraw()
                }}
            />
            <HomeButton title={Translate("WithdrawModal.Close")} onClick={() => { props.setOpenModal(false) }} />
        </ModalFooter>
    </Modal>
}

export default WithdrawModal;
