import { useWeb3React } from "@web3-react/core";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Input, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import HomeButton from "../../Components/HomeButton";
import Loader from "../../Components/Loader";
import { SingletonWeb3Instance } from "../../Web3";
import './index.css';
import PreseedStakeRow from './PreseedStakeRow';
import Bin from '../../Assets/trash1.png'
import { toByte32, toByteN, toEth, toUtf8, toWei } from "../../Handlers/converters";
import Web3 from "web3";
import { on } from "events";
import { IListing, ISalesRound } from "../../Common/Models";
import { PSPAD, STAKING_CONTRACT_ADDRESS, ZUSD } from "../../Common/TokenConfig";
import AddRoundModal from "../../Components/AddRoundModal";
import AddPackages from "../../Components/AddPackagesModal";
import Home from "../Home";
import ProjectModal from "../../Components/ProjectModal";

const AdminLogin = () => {
    const listingValues: any = {};
    const { account } = useWeb3React();
    const [listing, setListing] = useState(listingValues);
    const [isLoading, seisLoading] = useState(false)
    const [Auth, setAuth] = useState(false);
    let defaultRoundValues: string[] = [];
    const [coinName, setCoinName] = useState('')
    const [projectModalState, setProjectModalState] = useState(false)
    const [apyRate, setApyRate] = useState('')
    const [coinSymbol, setcoinSymbol] = useState('')
    const [roundList, setRoundList] = useState(defaultRoundValues);
    const [WhiteAddress, setWhiteAddress] = useState('');
    const [selectedRound, setSelectedRound] = useState('');
    const [range, setRange] = useState('')
    const [whiteListAddress, setWhiteListAddress] = useState('')
    const [activeRound, setActiveRound] = useState('');
    const [openAddRoundModal, setAddRoundModal] = useState<boolean>(false)
    const [openAddPackagesdModal, setAddPackagesModal] = useState<boolean>(false)
    const [roundInfo, setRoundInfo] = useState<ISalesRound>();
    const Web3Instance = SingletonWeb3Instance.getInstance();

    const [packages, setPackages] = useState([] = []);
    const stakingContractInstance = Web3Instance.stakingContractInstance;
    const projectContractInstance = Web3Instance.projectContractInstance;
    let whiteListContractInstance: any;

    useEffect(() => {
        SubmitHandler()
        CheckOwner();
    }, [account]);

    useEffect(() => {
        const AsyncFunc = async () => {
            const whitelist = await UpdateRoundInfo();
            ShowList(whitelist);
        }
        AsyncFunc();
    }, [selectedRound, roundInfo?.whiteList]);

    useEffect(() => {
        setSelectedRound(roundList[0]);
    }, [roundList]);

    const CheckOwner = async () => {
        const owner = await SingletonWeb3Instance.isOwner();
        setAuth(owner);
    }
    const ShowList = async (whiteList: any) => {
        if (selectedRound == "") return;
        SingletonWeb3Instance.subscribeTransaction(whiteList._address, [Web3.utils.sha3("WhiteListed(address,bool)")]!, (data) => {
            setWhiteListAddress(whiteList._address)
            const addr = Web3Instance.web3!.eth.abi.decodeParameter('address', data.topics[1]);
            const addrIsWhitelisted = Web3Instance.web3!.eth.abi.decodeParameters(['bool'], data.data);
            listing[addr.toString().toLowerCase()] = { address: addr.toString(), isWhitelisted: addrIsWhitelisted[0], packages: [] };
        });
        setListing(listing);
    }
    const UpdateRoundInfo = async () => {
        if (selectedRound == "") return;
        const roundInfo = await stakingContractInstance.methods.getRound(toByte32(selectedRound)).call({ from: account, gasLimit: 2000000 });
        const getPackageNames = await stakingContractInstance.methods.getRoundPackageNames(toByte32(selectedRound)).call({ from: account, gasLimit: 2000000 });
        const coinName = await Web3Instance.getTokenContract(roundInfo.stakingToken).methods.name().call({ from: account, gasLimit: 2000000 });
        const coinSymbol = await Web3Instance.getTokenContract(roundInfo.stakingToken).methods.symbol().call({ from: account, gasLimit: 2000000 });
        const maxRange = await stakingContractInstance.methods.getRemainingAllocation(toByte32(selectedRound)).call({ from: account, gasLimit: 2000000 })
        setRange(maxRange)
        setCoinName(coinName);
        setcoinSymbol(coinSymbol)
        setRoundInfo(roundInfo);
        setPackages(getPackageNames.map((p: string) => Web3.utils.toUtf8(p)));
        whiteListContractInstance = await Web3Instance.getWhiteListContract(roundInfo.whiteList);
        return whiteListContractInstance;

    };
    const ApyHandler = async () => {
        if (apyRate != '') {
            const apy = await stakingContractInstance.methods.setRewardRate(toWei(apyRate)).send({ from: account, gasLimit: 2000000 });
        }
        else {
            alert('Invalid APY reward rate')
        }
    }
    const ChangeRoundHandler = async () => {
        await stakingContractInstance.methods.setActiveRound(toByte32(selectedRound)).send({ from: account, gasLimit: 2000000 });
        alert('Active Round Changed');
    }
    const AddProject = async () => {
        setProjectModalState(true);
    }
    const SubmitHandler = async () => {
        if (account == undefined) { return; }
        const currentRoundName = await stakingContractInstance.methods.getActiveRoundName().call({ from: account, gasLimit: 2000000 })
        setActiveRound(Web3.utils.toUtf8(currentRoundName))
        const roundNames = await stakingContractInstance.methods.getRoundNames().call({ from: account, gasLimit: 2000000 })
        const rounds = roundNames.map((key: string, value: string) => { return Web3.utils.toUtf8(key); });

        setRoundList(rounds);
    };
    const AddRoundHandler = () => {
        setAddRoundModal(true)
    }
    const AddHandler = async () => {
        const isWhiteListed = await Web3Instance.getWhiteListContract(roundInfo!.whiteList).methods.isWhiteListed(WhiteAddress).call({ from: account, gasLimit: 2000000 })
        if (!isWhiteListed) {
            await Web3Instance.getWhiteListContract(roundInfo!.whiteList).methods.addToWhiteList(WhiteAddress).send({ from: account, gasLimit: 2000000 });
            listing[WhiteAddress.toLowerCase()] = { address: WhiteAddress, isWhitelisted: true, packages: [] };
        }
        else {
            alert('already white listed');
        }
        setWhiteAddress("");
    };

    const gasFeeAsync = async () => {
        await Web3Instance.getTokenContract(roundInfo!.stakingToken)
            .methods
            .approve(stakingContractInstance._address, toWei((10000000).toString()))
            .send({ from: account, gasLimit: 2000000 })
    };

    return (
        <Container fluid style={{ backgroundColor: '', color: 'wheat' }}>
            {
                Auth === false ? <h1>
                    Not Authorized

                </h1>
                    :
                    <div style={{ color: 'white' }}>
                        <Row style={{ marginTop: '3%', marginBottom: '3%' }}>

                            <Col md='4'>
                                <HomeButton title="Add Round"
                                    onClick={() => AddRoundHandler()
                                    }
                                />
                            </Col>
                            <Col md='4'>
                                <HomeButton title="Add Packages"
                                    onClick={() => setAddPackagesModal(true)
                                    }
                                />
                            </Col>
                            <Col md="4">
                                <h3>
                                    Active Round Name: {activeRound}
                                </h3>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '3%' }}>
                            <Col md="3">
                                <Input
                                    type="number"
                                    value={apyRate}
                                    onChange={(e) => { setApyRate(e.target.value) }}
                                    placeholder="Please enter the Apy Rate"
                                />
                            </Col>
                            <Col md="3">
                                <HomeButton
                                    title="Update APY"
                                    onClick={() => ApyHandler()}
                                />
                            </Col>
                            <Col md="3">
                                <HomeButton
                                    title="ADD IDO"
                                    onClick={() => AddProject()}
                                />

                            </Col>
                        </Row>
                        <Row>
                            <Col md="2" >
                                <select value={selectedRound} onChange={(e) => setSelectedRound(e.target.value)}>
                                    {
                                        roundList.map((key, value) => {
                                            return (
                                                <option key={key} value={(key)} >
                                                    {key}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </Col>
                            <Col md="8"  >
                                <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {selectedRound} Sale - Whitelist PSPAD Wallets
                                </h2>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="3">
                                <HomeButton
                                    title="Approve Fees"
                                    onClick={() => gasFeeAsync()}
                                />
                            </Col>
                            <Col md="3">
                                <HomeButton
                                    title="Make This Active Round"
                                    onClick={() => ChangeRoundHandler()}
                                />
                            </Col>
                        </Row>
                        <br />
                        <p>
                            COIN NAME  & UNIT :
                            {coinName} {coinSymbol}
                        </p>
                        <p>
                            Remainig Allocation :
                            {
                                toEth(range)
                            }
                        </p>
                        {selectedRound != '' &&
                            <Fragment>
                                <Row>
                                    <Col md="6">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '' }}>
                                            <Input
                                                className="inputFields"
                                                value={WhiteAddress}
                                                onChange={(e) => setWhiteAddress(e.target.value)}
                                            />
                                        </div>
                                        <div style={{ width: '40%', paddingBottom: '7%', paddingTop: '7%' }}>
                                            <HomeButton
                                                title="Whitelist"
                                                onClick={AddHandler}
                                                disabled={!WhiteAddress}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="3">

                                        <div style={{ width: '40%', paddingBottom: '7%', paddingTop: '7%' }}>


                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5" >
                                        <h5 style={{ fontSize: '1vw' }} >
                                            {selectedRound} - Whitelisted Addresses - Staking {roundInfo?.stakingToken}
                                        </h5>
                                    </Col>
                                </Row>
                                <AddRoundModal openModal={openAddRoundModal} setOpenModal={setAddRoundModal} allValues={whiteListAddress} />
                                <AddPackages openModal={openAddPackagesdModal} setOpenModal={setAddPackagesModal} />
                                <ProjectModal openModal={projectModalState} setOpenModal={setProjectModalState} />
                            </Fragment>
                        }
                        {
                            Object.keys(listing).map((key, index) => {
                                return (
                                    <div key={key}>
                                        <PreseedStakeRow Address={key}
                                            RowIndex={index}
                                            Listing={listing[key]}
                                            isLoading={isLoading}
                                            Packages={packages}
                                            Round={selectedRound}
                                            onStaked={() => null} />
                                    </div>
                                )
                            })
                        }
                    </div>

            }
        </Container>
    )
};
export default AdminLogin;