import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import Web3 from "web3";
import { ZUSD } from "../../Common/TokenConfig";
import ErrorModal from "../../Containers/Header/ErrorModal";
import { toByte32, toByteN, toEth, toWei } from "../../Handlers/converters";
import { uniqueArray } from "../../Handlers/utilities";
import { SingletonWeb3Instance } from "../../Web3";
// import 'bootstrap/dist/css/bootstrap.min.css';
import HomeButton from "../HomeButton";

interface IProps {
    openModal: boolean;
    setOpenModal: Function
    allValues?: Object

}

interface ICoinBalance {
    coinAddress: string;
    coinBalance: string;
    coinName: string;
}


const AddRoundModal = (props: IProps) => {
    const { account } = useWeb3React();
    const [roundName, setRoundName] = useState('');
    const [startDate, setStartDate] = useState<any>(Math.round((new Date()).getTime() / 1000));
    const [endDate, setEndDate] = useState<any>(Math.round((new Date()).getTime() / 1000) + 3600);
    const [maxAllocation, setMaxAllocation] = useState<string>('');
    const [isPaused, setisPaused] = useState<any>();
    const [isWhitelistOnly, setisWhitelistOnly] = useState<any>();
    const [stakingToken, setstakingToken] = useState<any>();
    const [whiteList, setwhiteList] = useState(props.allValues);
    const [unstakeMonthlyLimitPercent, setunstakeMonthlyLimitPercent] = useState<number>();
    const [unstakeMonthlyPremiumPercent, setunstakeMonthlyPremiumPercent] = useState<number>();
    const [vestingDays, setvestingDays] = useState<number>();
    const [allocationMultiplierPercent, setallocationMultiplierPercent] = useState<number>();
    const [allocatedAmountFirstReleasePercent, setallocatedAmountFirstReleasePercent] = useState<number>();
    const [refferalCodePercentage, setRefferalCodePercentage] = useState<number>();
    const [allocatedAmountMonthlyReleasePercent, setallocatedAmountMonthlyReleasePercent] = useState<number>();
    const [tokenPrice, setTokenPrice] = useState<number>();
    const [allowWithdrawInVestingPeriod, setallowWithdrawInVestingPeriod] = useState<any>();
    const [openErrorModal, setErrorModal] = useState<boolean>(false);
    const [ErrorText, setErrorText] = useState<string>("All filelds are required");
    const [reward, setReward] = useState<string>('')
    const [coins, setCoins] = useState<ICoinBalance[]>([])
    const [coinName, setCoinName] = useState('')
    // const [roundFields, setRoundFiedls] = useState<any>({
    //     roundName: '',
    //     maxAllocation: '',
    //     startDate: Math.round((new Date()).getTime() / 1000),
    //     endDate: Math.round((new Date()).getTime() / 1000) + 3600,
    //     isPaused: false,
    //     isWhitelistOnly: true,
    //     stakingToken: ZUSD,
    //     whiteList: '0xd528a533599223CA6B5EBdd1C32A241432FB1AE8',
    //     packageNames: [],
    //     unstakeMonthlyLimitPercent: 20,
    //     unstakeMonthlyPremiumPercent: 25,
    //     vestingDays: 112,
    //     allocationMultiplierPercent: 10,
    //     allocatedAmountFirstReleasePercent: 10,
    //     allocatedAmountMonthlyReleasePercent: 0,
    //     allowWithdrawInVestingPeriod: false

    // })
    const Web3Instance = SingletonWeb3Instance.getInstance();
    const stakingContractInstance = Web3Instance.stakingContractInstance;

    // props.allValues =  roundFields 
    // const bronzePackage = {
    // name: toByteN("Gold", 32),
    // stakingAmount: Web3.utils.toWei("10", 'ether'),
    // poolWeight: "3",
    // maxUsers: "60"
    // };

    useEffect(() => {
        getBalanceFromWeb3();
    }, [])

    const getBalanceFromWeb3 = async () => {
        const stakingContractPlaceholder = Web3Instance.stakingContractInstance
        const stakedAmounts = await stakingContractPlaceholder.methods.getStakedAll().call({ from: account })
        const coinsArray = await Promise.all(stakedAmounts.map(async (coin: ICoinBalance) => {
            const coinName = await Web3Instance.getTokenContract(coin.coinAddress).methods.symbol().call({ from: account });
            return { coinName: coinName, coinAddress: coin.coinAddress, coinBalance: coin.coinBalance };
        }));
        setCoins(uniqueArray(coinsArray));
        return;
    };
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const toggle = (tab: any) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    const handleNumberFieldChange = (evt: any, key: any) => {
        if (rx_live.test(evt.target.value)) { key(evt.target.value) }
        else {
            key('')
        }
    }
    const SubmitRoundHandler = async () => {
        const obj = {
            roundName: roundName && toByteN((roundName), 32),
            maxAllocation: maxAllocation && toWei(maxAllocation),
            startDate: (new Date(startDate)).getTime(),
            endDate: (new Date(endDate)).getTime(),
            isPaused,
            isWhitelistOnly,
            stakingToken,
            whiteList: props.allValues,
            unstakeMonthlyLimitPercent,
            packageNames: [],
            unstakeMonthlyPremiumPercent,
            vestingDays,
            allocationMultiplierPercent,
            allocatedAmountFirstReleasePercent,
            allocatedAmountMonthlyReleasePercent,
            allowWithdrawInVestingPeriod,
            refferalCodePercentage
        }
        const valid = Object.values(obj).every((v) => v ? true : false)
        // console.log(stakingToken);
        if (valid) {
            try {

                await stakingContractInstance.methods.addRound(obj).send({ from: account, gas: 2000000 });
                props.setOpenModal(false)
            }
            catch {
                props.setOpenModal(false)
                alert("Something went wrong")
            }
        }
        else {
            alert("Please fill all fields")
        }
    }

    const inputLableStyle = {
        marginBottom: 2
    }

    return <Modal
        isOpen={props.openModal}
        centered={true}
        backdrop={true}
        size="md"
        style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}
    >
        <ModalHeader className="withdrawModalContainer">
            <h1>Add Round</h1>

        </ModalHeader>
        <ModalBody className="withdrawModalContainer" style={{ borderColor: 'transparent' }}>
            <div style={{
                display: 'block'
            }}>

                <Nav tabs style={{ borderColor: 'transparent' }}>
                    <NavItem >
                        <NavLink
                            className="TabsView"

                            active={currentActiveTab == '1'}
                            onClick={() => { toggle('1'); }}
                        >
                            Round
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className="TabsView"
                            active={currentActiveTab == '2'}
                            onClick={() => { toggle('2'); }}
                        >
                            Staking
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className="TabsView"
                            active={currentActiveTab == '3'}
                            onClick={() => { toggle('3'); }}
                        >
                            Allocation
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent className="TabContent" activeTab={currentActiveTab}>
                    <TabPane tabId="1" className="TabContent">
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Round Name
                            </p>
                            <Input
                                className="inputFields"
                                value={roundName}
                                onChange={(e) => setRoundName(e.target.value)}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Max Allocation
                            </p>
                            <Input
                                className="inputFields"
                                value={maxAllocation}
                                name="maxAllocation"
                                onChange={(e) => { handleNumberFieldChange(e, setMaxAllocation) }}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Start Date
                            </p>
                            <Input
                                type="date"
                                className="inputFields"
                                value={startDate}
                                onChange={(e) => { setStartDate((e.target.value)) }}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                End Date
                            </p>
                            <Input
                                type="date"
                                className="inputFields"
                                value={endDate}
                                onChange={(e) => { setEndDate((e.target.value)) }}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Is Paused
                            </p>
                            <Input
                                type="select"
                                className="inputFields"
                                value={isPaused}
                                onChange={(e) => { setisPaused(e.target.value) }}

                            >
                                <option value="">Select</option>
                                <option value="True">True</option>
                                <option value="False">False</option>
                            </Input>
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Is WhiteListed
                            </p>
                            <Input
                                type="select"
                                className="inputFields"
                                value={isWhitelistOnly}
                                onChange={(e) => { setisWhitelistOnly(e.target.value) }}
                            >
                                <option value="">Select</option>
                                <option value="True">True</option>
                                <option value="False">False</option>
                            </Input>
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p>
                                Coin Name
                            </p>
                            <Input
                                type="select"
                                className="inputFields"
                                value={stakingToken}
                                onChange={(e) => { setstakingToken(e.target.value) }}

                            >
                                <option value="">Select</option>
                                {coins.map((key: any,) => <option key={key.coinAddress} value={(key.coinAddress)}>{key.coinName}</option>)}

                            </Input>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2" className="TabContent">
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Un Stake Limit Percentage
                            </p>
                            <Input
                                className="inputFields"
                                value={unstakeMonthlyLimitPercent}
                                onChange={(e) => { handleNumberFieldChange(e, setunstakeMonthlyLimitPercent) }}
                            // onChange={(e) => setunstakeMonthlyLimitPercent(parseInt(e.target.value))}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Un Stake Premium Percentage
                            </p>
                            <Input
                                className="inputFields"
                                value={unstakeMonthlyPremiumPercent}
                                onChange={(e) => { handleNumberFieldChange(e, setunstakeMonthlyPremiumPercent) }}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Token Price
                            </p>
                            <Input
                                className="inputFields"
                                value={tokenPrice}
                                onChange={(e) => { handleNumberFieldChange(e, setTokenPrice) }}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                vesting Days
                            </p>
                            <Input
                                className="inputFields"
                                value={vestingDays}
                                onChange={(e) => { handleNumberFieldChange(e, setvestingDays) }}
                            // onChange={(e) => setvestingDays(parseInt(e.target.value))}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Allocation Multiplier Percent
                            </p>
                            <Input
                                className="inputFields"
                                value={allocationMultiplierPercent}
                                onChange={(e) => { handleNumberFieldChange(e, setallocationMultiplierPercent) }}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Refferal Code Percentage
                            </p>
                            <Input
                                className="inputFields"
                                value={refferalCodePercentage}
                                onChange={(e) => { handleNumberFieldChange(e, setRefferalCodePercentage) }}
                            />
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Allow to Withdraw in Vesting Period
                            </p>
                            <Input
                                type="select"
                                className="inputFields"
                                value={allowWithdrawInVestingPeriod}
                                onChange={(e) => { setallowWithdrawInVestingPeriod(e.target.value) }}

                            >
                                <option value="">Select</option>
                                <option value="True">True</option>
                                <option value="False">False</option>
                            </Input>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3" className="TabContent">
                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                First Release Amount Percent
                            </p>
                            <Input
                                className="inputFields"
                                value={allocatedAmountFirstReleasePercent}
                                onChange={(e) => { handleNumberFieldChange(e, setallocatedAmountFirstReleasePercent) }}
                            />
                        </Row>

                        <Row style={{ display: 'flex' }}>
                            <p style={inputLableStyle} >
                                Monthly Release Amount Percent
                            </p>
                            <Input
                                className="inputFields"
                                value={allocatedAmountMonthlyReleasePercent}
                                onChange={(e) => { handleNumberFieldChange(e, setallocatedAmountMonthlyReleasePercent) }}
                            />
                        </Row>

                    </TabPane>
                </TabContent>
            </div >
        </ModalBody>
        <ErrorModal openModal={openErrorModal} text={ErrorText} setOpenModal={() => setErrorModal(false)} />
        <ModalFooter className="withdrawModalContainer">
            <HomeButton
                title="Close"
                onClick={() => props.setOpenModal(false)}
            />
            <HomeButton
                title="Create"
                onClick={() => SubmitRoundHandler()}
            />
        </ModalFooter>
    </Modal>
}
export default AddRoundModal;