import React from "react";
import "./styles.css";

interface IProps {
    imageSource:string
    route?: string
    onclick?: string
    style:any
}

const FooterIcon = (props: IProps) => {
    return <div className="FooterIcon" >
        <a href={props.route} onClick={() => props.onclick}>
            <img src={props.imageSource} alt="Footer Icon" style={props.style} />
        </a>
    </div>
}

export default FooterIcon;
