import React from "react";
import { Col, Row } from 'reactstrap';
import { convertDate, convertToDateTime } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";


interface IProps {
    Data: any,
    tokenName: string,
    tokenSymbol: string,
    FCFS: any,
    FCFSPREP: any,
    FCFSCLOSED: any,
    FCFSPREPCLOSED: any,
    seedOpen: any,
    seedClosed: any
}

const SchedulesTab = (props: IProps) => {

    return (

        <>
            <Row>

                <Col xs="12" md="6" >
                    {/* <div className="TabHeader" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p >
                            Pool Information
                        </p>
                        <p className="projectdetail-righttable">
                            Open
                        </p> <p className="projectdetail-righttable">
                            closes
                        </p>
                    </div> */}
                    <Row>
                        <Col className="projectdetail-righttable">Pool Information</Col>
                        <Col className="projectdetail-righttable" style={{ textAlign: 'center' }}> Opens</Col>

                        <Col className="projectdetail-righttable" style={{ textAlign: 'center' }}> Closes</Col>


                    </Row>
                    <br />
                    <Row>
                        <Col className="projectdetail-lefttable">Allocation</Col>
                        <Col className="projectdetail-righttable"> {convertToDateTime(props.seedOpen)} </Col>
                        <Col className="projectdetail-righttable"> {convertToDateTime(props.seedClosed)}</Col>
                    </Row>
                    <br />

                    <Row>
                        <Col className="projectdetail-lefttable">FCFS - Prepare</Col>
                        <Col className="projectdetail-righttable">{convertToDateTime(props.FCFS)}</Col>
                        <Col className="projectdetail-righttable"> {convertToDateTime(props.FCFSCLOSED)} </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col className="projectdetail-lefttable">FCFS</Col>
                        <Col className="projectdetail-righttable">{convertToDateTime(props.FCFSPREP)}</Col>
                        <Col className="projectdetail-righttable">{convertToDateTime(props.FCFSPREPCLOSED)}</Col>
                    </Row>

                </Col>

                <Col md='1' className="divider-vertical">
                </Col>

                <Row className="divider-horizontal">
                    <hr />
                </Row>


                <Col xs="12" md="3">

                    <div className="TabHeader">
                        {Translate("ProjectPage.ProjectPageTabRight")}
                    </div>
                    <Row>
                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.TokenName")}</Col>
                        <Col className="projectdetail-righttable"> {props.tokenName} </Col>
                    </Row>
                    <Row>
                        <br />

                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.TokenSymbol")}</Col>

                        <Col className="projectdetail-righttable">{props.tokenSymbol}</Col>

                    </Row>
                </Col>
            </Row>

            <Row>
                <hr style={{ borderTop: "2px dotted gray", marginTop: "10px" }} />
            </Row>
        </>
    )
}


export default SchedulesTab;