import React from "react";
import { Col } from "reactstrap";
import "./styles.css";

interface IProps {
    imageSource: string;
    heading: string;
    active?: boolean
}

const StakingIconComponent = (props: IProps) => {
    return <Col md={2}>
        <div className="StakingStatusIconContainer" >
            <div className="StakingStatusIcon"><img src={props.imageSource} /></div>
            <div className="StakingStatusIconHeading" style={{ color: props.active && "#c361ba" || "" }} >{props.heading}</div>
        </div>
    </Col>
}

export default StakingIconComponent;
