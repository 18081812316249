import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { IPackage } from "../../Common/Models";
import { STAKING_CONTRACT_ADDRESS } from "../../Common/TokenConfig";
import { toByte32, toEth, toWei } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { STEP_2 } from "../../Routes/routeNames";
import { SingletonWeb3Instance } from "../../Web3";
import "./styles.css";

interface IProps {
    account: string | null | undefined
    disableHandler: Function
    history: Array<string>
    package: IPackage,
    refferalCode: any
}

const PreAuthContainer = (props: IProps) => {
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const pspApprovalWeb3 = async () => {
        // props.disableHandler(false)
        const prevLocation = props.history[props.history.length - 1]
        const activeRoundName = await Web3Instance.stakingContractInstance.methods.getActiveRoundName().call({ from: props.account });
        const activeRound = await Web3Instance.stakingContractInstance.methods.getRound(activeRoundName).call({ from: props.account });
        const tokenContractPlaceholder = Web3Instance.getTokenContract(activeRound.stakingToken);

        try {
            const maxRange = await Web3Instance.stakingContractInstance.methods.getRemainingAllocation((activeRoundName)).call({ from: props.account })
            const response = await tokenContractPlaceholder.methods.allowance(props.account, STAKING_CONTRACT_ADDRESS).call({ from: props.account })
            let total = parseFloat(props.package.stakingAmount + toEth(maxRange))
            await tokenContractPlaceholder.methods.approve(STAKING_CONTRACT_ADDRESS, (toWei(props.package.stakingAmount)).toString()).send({ from: props.account, gasLimit: 900000 })
            props.disableHandler(false)
           
        } catch (error) {
            alert(error)
            props.disableHandler(true)
        }
    }
    useEffect(() => {
        props.disableHandler(true)

        pspApprovalWeb3()

    }, [])

    return <Row className="PreAuthAmountContainer">
        <Col md={10} className="offset-md-1 d-flex flex-column align-items-center justify-content-between " >
            <div className="PreAuthHeading">{Translate("Staking.PreAuthHeading1")}</div>
            <div className="PreAuthSubHeading">{Translate("Staking.PreAuthContent1")}</div>
            <div className="PreAuthSubHeading">{Translate("Staking.PreAuthContent2")}</div>
            <div className="PreAuthHeading">{Translate("Staking.PreAuthHeading2")}</div>
            <div className="PreAuthSubHeading">{Translate("Staking.PreAuthContent3")}</div>
            <div className="PreAuthSubHeading">{props.account}</div>
        </Col>
    </Row>
}

export default PreAuthContainer;
