import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routes from "./Routes";
import { useWeb3React, Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import WalletConnectProvider from "@walletconnect/web3-provider";
import { injected } from './Common/InjectedChainIds';
import { disconnect } from 'process';

function getLibrary() {
  const provider1 = new WalletConnectProvider({
    rpc: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      1287: "https://rpc.api.moonbase.moonbeam.network",
      5777: "http://127.0.0.1:8545",
      1281: "http://192.168.100.37:9933",
    },
  });
  return new Web3(provider1._provider)
}
function MetamaskProvider({ children }: { children: JSX.Element }): JSX.Element {
  const { active: networkActive, error: networkError, activate: activateNetwork, deactivate } = useWeb3React()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        setLoaded(false)
        if (localStorage.getItem('key') == 'true') {
          activateNetwork(injected)
        }
        else if (localStorage.getItem('key') === 'false') {
          disconnect()
        }
      })
      .catch(() => {
        setLoaded(false)

      })
  }, [activateNetwork, networkActive, networkError])

  return <>
    <Routes />
  </>
}


function App() {
  return (
    <div className="app-background">
      <Web3ReactProvider getLibrary={getLibrary} >
        <BrowserRouter >
          <MetamaskProvider>
            <div style={{ backgroundColor: "rgba(0,0,0, 0.1)" }} >
              <Routes />
            </div>
          </MetamaskProvider>
        </BrowserRouter>
      </Web3ReactProvider>
    </div>

  );
}

export default App;
