import { useWeb3React } from "@web3-react/core";
import React, { Fragment, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import HomeButton from "../../Components/HomeButton";
import StakingStats from "../../Components/StakingStats";
import WithdrawModal from "../../Components/WithdrawModal";
import { toByte32, toEth, toWei, Utils } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { uniqueArray } from "../../Handlers/utilities";
import web3 from "web3";
import * as RouteNames from "../../Routes/routeNames";
import { SingletonWeb3Instance } from "../../Web3";

const StakingStatsContainer = (props: any) => {

    interface ICoinBalance {
        coinAddress: string;
        coinBalance: string;
        coinName: string;
    }

    const navigate = useNavigate()
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const { active, account } = useWeb3React();
    const [reward, setReward] = useState<string>('')
    const [unstakedAmount, setUnstakedAmount] = useState<number>(0)
    const [stakedAmount, setStaked] = useState<ICoinBalance[]>([])
    const [openWithDrawModal, setWithdrawModal] = useState<boolean>(false)
    const stakingContractPlaceholder = Web3Instance.stakingContractInstance
    const SendReward = async () => {
        const apyCal = await stakingContractPlaceholder.methods.calculateApy().call({ from: account, gasLimit: 200000 });
        console.log(apyCal);


    }
    useEffect(() => {
        getBalanceFromWeb3();
    }, [active, account])

    const getBalanceFromWeb3 = async () => {


        if (account !== (null || undefined)) {
            const stakedAmounts = await stakingContractPlaceholder.methods.getStakedAll().call({ from: account });
            // const totalRewards = await stakingContractPlaceholder.methods.calculateApy().call({ from: account });
            // // var exponent = totalRewards.toExponential
            // setReward(toEth(totalRewards));
            setReward('0')
            // setUnstakedAmount()
            const stakedAmountsWithName = await Promise.all(stakedAmounts.map(async (coin: ICoinBalance) => {
                const coinName = await Web3Instance.getTokenContract(coin.coinAddress).methods.symbol().call({ from: account });
                return { coinName: coinName, coinAddress: coin.coinAddress, coinBalance: coin.coinBalance };
            }));
            setStaked(uniqueArray(stakedAmountsWithName));
            return;
        }
    };
    return <>
        <StakingStats heading={Translate("Staking.StakingStatusHeading4")}>
            <Row>{
                stakedAmount.map((coin: ICoinBalance, index: number) => {
                    return (
                        <Col key={coin.coinName}>
                            <p><b>{coin.coinName}</b><br /><span style={{ color: "#c361ba" }}>{(toEth(coin.coinBalance))}</span></p>
                        </Col>
                    );
                })
            }</Row>
        </StakingStats>
        <StakingStats heading={Translate("Staking.StakingStatusHeading5")} content={unstakedAmount} />
        <StakingStats heading={Translate("Staking.StakingStatusHeading6")} content={reward}>
            <div className="RewardsContainer" >
                {/* <HomeButton

                    title={"Check Rewards"}
                    onClick={() => {
                        SendReward()
                    }}
                    style={{ width: "70%", margin: "1vh" }} /> */}
                <HomeButton
                    title={Translate("Button.Withdraw")}
                    onClick={() => { setWithdrawModal(true) }}
                    style={{ width: "70%", margin: "1vh" }} />
            </div>
        </StakingStats>
        <WithdrawModal openModal={openWithDrawModal} setOpenModal={setWithdrawModal} rewardAmount={reward.toString()} />
    </>
}

export default StakingStatsContainer;
