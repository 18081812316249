import React from "react";
import { Col } from "reactstrap";
import "./styles.css";

interface IProps {
    heading?: string
    children?: any
    content?: any
}

const StakingStats = (props: IProps) => {
    return <Col className="text-white">
        <div className="StakingStatsMainContainer">
            <div className="StakingStatsSubContainer">
                <div className="StakingStatsHeading">{props.heading}</div>
                <div className="StakingStatsSeparator" />
                <p className="StakingStatusContent">
                    {props.content}
                </p>
                {props.children}

                {/* <div>{props.children}</div> */}


            </div>
        </div>
        <br />
    </Col>
}

export default StakingStats;
