import React from "react";
import { Button } from "reactstrap";
import "./styles.css";

interface IProps {
    title: string
    style?: any
    onClick: Function
    children?: any
    disabled?: boolean
    isFocused?: boolean
}

const HomeButton = (props: IProps) => {
    return <Button color="transparent" className={props.isFocused ? 'HomeButtonFocused' : "HomeButton"} style={props.style} onClick={() => props.onClick()} disabled={props.disabled} >
        {props.title}
        {props.children}
    </Button>
}

export default HomeButton;
