export const HOME = "Home"
export const HOW_IT_WORKS = "How_It_Works"
export const TOKEN_INFO = "Token_Info"
export const FEATURES = "Features"
export const ADMIN = "ADMIN"
export const ABOUT_US = "About_Us"
export const SOCIAL_MEDIA = "Social_Media"
export const STEP_1 = "step_1"
export const STEP_2 = "step_2"
export const STEP_3 = "step_3"
export const STEP_4 = "step_4"
export const STEP_5 = "step_5"
export const STAKING = "staking"
export const UNSTAKING = "unstaking"
export const PROJECTS = "projects"
export const PRIVACY = "privacy"
export const COOKIES = "cookies"
export const DATASCREEN ="Data_Screen"