
import web3 from "web3";

export const toWei = (ethValue: any) => {
    return web3.utils.toWei(ethValue).toString()

}
export const toEth = (weiValue: any) => {

    return web3.utils.fromWei(weiValue).toString()
}
export const convertDate = (unixDate: any) => {
    let unix_timestamp = unixDate;
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unix_timestamp * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
}
export const convertToDateTime = (unixtime: any) => {

    const test1 = unixtime * 1000;
    const datetime = new Date(test1);

    return (datetime.toLocaleString())
}
export const Utils = web3.utils

export const toByte32 = (data: any) => {
    return web3.utils.padRight(web3.utils.asciiToHex(data), 32)
}

export const toByteN = (data: any, byte: any) => {
    return web3.utils.padRight(web3.utils.asciiToHex(data), byte * 2)
}

export const toUtf8 = (data: any) => {
    return web3.utils.toUtf8(data);
}
export const toUtf8ToHex = (data: any) => {
    return web3.utils.hexToUtf8(data);
}