import { useWeb3React } from "@web3-react/core";
import { TIMEOUT } from "dns";
import { access } from "fs";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from 'reactstrap';
import Web3 from "web3";
import { convertDate, convertToDateTime, toEth, toUtf8, toUtf8ToHex, toWei } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";

interface IProps {
    Data: any,
    Open: any,
    FFCFSOpens: any,
    closed: any,
    swapRate: any,
    cap: any,
    accessType: any
    tokenName: any,
    tokenSymbol: any,
    tokenNames: any
}

const ProjectDetailsTab = (props: IProps) => {
    const ParseData = JSON.stringify(props.Data)
    const [opens, setOpens] = useState<string>('');
    const [FCFSOpens, setFcFsopens] = useState<any>('');
    const [closes, setCloses] = useState<string>('');
    const [swapRate, setSwapRate] = useState<string>('');
    const [cap, setCap] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [symbol, setSymbol] = useState<string>('');
    const [acccess, setAccess] = useState<string>('');

    const { account } = useWeb3React();


    const Handler = async () => {

        const Web3Instance = SingletonWeb3Instance.getInstance();

        const projectContractInstance = Web3Instance.projectContractInstance;

        const details = await projectContractInstance.methods.getAllProjects().call({ from: account });
        await setName(toUtf8(details[props.Data][0]))
        setSymbol(toUtf8(details[props.Data][1]))
        setAccess(toUtf8(details[props.Data][5]))
        setSwapRate((toEth(details[props.Data][4]) + "%"))
        setCap(toEth(details[props.Data][7]));
        setOpens(convertDate((details[props.Data][10])))
        var temp = (details[props.Data][13]);
        setFcFsopens(convertDate(temp))
        setCloses(convertDate(details[props.Data][12]))

    }

    useEffect(() => {
        // Handler();
        // alert(Web3.utils.hexToUtf8((props.accessType)))

    }, [props.Data])


    return (
        <>

            <Row >
                <Col md="6" xs="12">
                    <div className="TabHeader">
                        {Translate("ProjectPage.ProjectPageTabLeft")}
                    </div>
                    <Row>
                        <br />
                        <br />

                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.Opens")}</Col>
                        <Col className="projectdetail-righttable">{convertToDateTime(props.Open)} </Col>

                    </Row>
                    <Row >
                        <br />
                        <br />

                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.FCFCOpens")}</Col>
                        <Col className="projectdetail-righttable">{convertToDateTime(props.FFCFSOpens)} </Col>

                    </Row>
                    <Row>
                        <br />
                        <br />
                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.Closes")}</Col>
                        <Col className="projectdetail-righttable">{convertToDateTime(props.closed)} </Col>

                    </Row>
                    <Row>
                        <br />
                        <br />

                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.SwapRate")}</Col>
                        <Col className="projectdetail-righttable">{toEth(props.swapRate)} {props.tokenName} = {"1 "+ " "+ props.tokenNames} </Col>

                    </Row>
                    <Row>
                        <br />
                        <br />

                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.Cap")}</Col>
                        <Col className="projectdetail-righttable">{toEth(props.cap)} </Col>

                    </Row>
                    <Row>
                        <br />
                        <br />

                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.TotalUsersParticipated")}</Col>
                        <Col className="projectdetail-righttable">{"TBA"} </Col>
                    </Row>
                    <Row>
                        <br />
                        <br />

                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.TotalFundsSwapped")}</Col>
                        <Col className="projectdetail-righttable">{"TBA"} </Col>


                    </Row>
                    <Row>
                        <br />

                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.AccessType")}</Col>
                        <Col className="projectdetail-righttable">{(props.accessType)} </Col>

                    </Row>

                </Col>

                <Col md='1' className="divider-vertical">
                </Col>

                <Row className="divider-horizontal">
                    <hr />
                </Row>

                <Col md="3" xs="12">

                    <div className="TabHeader">

                        {Translate("ProjectPage.ProjectPageTabRight")}
                    </div>
                    <Row>
                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.TokenName")}</Col>
                        <Col className="projectdetail-righttable">{(props.tokenName)}</Col>
                    </Row>
                    <Row>
                        <Col className="projectdetail-lefttable">{Translate("ProjectPage.TokenSymbol")}</Col>
                        <Col className="projectdetail-righttable">{props.tokenSymbol}</Col>
                    </Row>
                </Col>

            </Row>

            <Row>
                <hr style={{ borderTop: "2px dotted gray", marginTop: "10px" }} />
            </Row>
        </>
    )
}

export default ProjectDetailsTab;