import React from "react";
import {
    Modal, ModalBody, ModalFooter
} from 'reactstrap';
import Alert from "../../Assets/Alert.png";
import HomeButton from "../../Components/HomeButton";
import "./styles.css";

interface IProps {
    openModal: boolean;
    setOpenModal: Function
    text: string
}

const ErrorModal = (props: IProps) => {
    return (
        <Modal
            isOpen={props.openModal}
            centered={true}
            backdrop={true}
            size="sm"
        >
            <ModalBody className="modalContainer">
                <img className="ErrorModalIcon" src={Alert} />
                <div>
                    {props.text}
                </div>
            </ModalBody>
            <ModalFooter className="modalContainer">
                <HomeButton title={"Close"} onClick={() => props.setOpenModal(false)} />
            </ModalFooter>
        </Modal>

    )
}


export default ErrorModal;