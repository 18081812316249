import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Col, Row } from "reactstrap";
import { CONNECTED, PSPAD, ZUSD } from "../../Common/TokenConfig";
import StakingCheckpoints from "../../Components/StakingCheckpoints";
import { BNB_SubUnit } from "../../Constants/units";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";
import "./styles.css";
import { toEth } from "../../Handlers/converters";

const Big = require('big.js');

interface IProps {
    disableHandler: Function
}

const UnStakingCheckpointsContainer = (props: IProps) => {
    const { active, account } = useWeb3React();
    const [pspBalance, setPspBalance] = useState<number>(0)
    const [bnbBalance, setBNBBalance] = useState<number>(0)
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const minPspBalance = 0.000001
    const minBNBBalance = 0.000001
    const walletConnectionCheck: boolean = active;
    const bnbBalanceCheck: boolean = bnbBalance > minBNBBalance;
    const pspBalanceCheck: boolean = pspBalance > minPspBalance;

    const getBalanceFromWeb3 = async () => {
        if (account !== (null || undefined)) {
            const pspBalanceResponse = await Web3Instance.getTokenContract(ZUSD).methods.balanceOf(account).call({from:account});
            const usdtBalanceResponse = await Web3Instance.getTokenContract(ZUSD).methods.balanceOf(account).call();
            const bnbBalanceResponse = await Web3Instance.web3!.eth.getBalance(account!)
            setPspBalance(parseFloat(toEth(pspBalanceResponse)))
            setBNBBalance(parseFloat(toEth(bnbBalanceResponse)))
            props.disableHandler(false)
            return
        }
        setPspBalance(0)
        setBNBBalance(0)
        props.disableHandler(true)
    }

    useEffect(() => {
        getBalanceFromWeb3()
    }, [active, account])

    return <Row className="UnstakingCheckpointsContainer">
        <h3 className="UnstakingCheckpointsHeadingMain">{Translate("UnStaking.CheckpointsHeading")}</h3>
        <h4 className="UnstakingCheckpointsHeading">{Translate("UnStaking.CheckpointsContent")}</h4>

        <Col xl={4} md={6} xs={12} >
            <StakingCheckpoints mainHeading={Translate("UnStaking.StakingCheckpointHeading1")} subHeading={Translate("UnStaking.StakingCheckpointSubHeading1")} checked={walletConnectionCheck} />
        </Col>
        <Col xl={4} md={6} xs={12} >
            <StakingCheckpoints mainHeading={Translate("UnStaking.StakingCheckpointHeading2")} subHeading={`${Translate("UnStaking.StakingCheckpointSubHeading2")} ${pspBalance}`} checked={pspBalanceCheck} />
        </Col>
        <Col xl={4} md={6} xs={12} >
            <StakingCheckpoints mainHeading={Translate("UnStaking.StakingCheckpointHeading3")} subHeading={`${Translate("UnStaking.StakingCheckpointSubHeading3")} ${bnbBalance}`} checked={bnbBalanceCheck} />
        </Col>
    </Row>
}

export default UnStakingCheckpointsContainer;
