import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import "./styles.css";

class Features extends Component {

    render() {
        return (
            <Container fluid className="text-white">

                <Row className="mt-3">
                    <Col xs="12" className="text-left">
                        <h2>PolkaStarterPad Tiers</h2>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs="12" className="text-left">
                        <h5>Project tokens offered on PolkaStarterPad IDO's will be split into the following pools</h5>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs="12" className="text-left">
                        To participate in IDO’s, you must be staking PolkaStarterPad and have passed the one-time whitelist process at least 3 hours prior to the IDO time.
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs="12" md="6">
                        <Row className="justify-content-center">
                            <Col xs="11">
                                <Row className="FeaturesTabContainer">
                                    <h4 style={{ fontWeight: 'bold' }}>PolkaStarterPad Lottery</h4>
                                    <p>We have reserved 10% of each IDO for lottery allocation.</p>
                                    <p>Participants in lottery tier will have to stake $500 Polkadot and pool weight is 0.5x.</p>
                                    <Col md="12" >
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                            <p>One lottery ticket</p>
                                            <p className="FeatureFieldHeaderStyle">$500 Polkadot</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                            <p>Two lottery ticket</p>
                                            <p className="FeatureFieldHeaderStyle">$800 Polkadot</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                            <p>Three lottery ticket</p>
                                            <p className="FeatureFieldHeaderStyle">$1000 Polkadot</p>
                                        </div>

                                    </Col>
                                    <Col md="5" >
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="6" className="mt-5 mt-md-0">
                        <Row className="justify-content-center">
                            <Col xs="11">
                                <Row className="FeaturesTabContainer">
                                    <h4 style={{ fontWeight: 'bold' }}>PUBLIC ROUND TIER SYSTEM STRUCTURE:</h4>
                                    <p>PSPad Tier system breakdown:</p>
                                    <p>Tier system is divided into four tiers and a lottery allocation.</p>
                                    <p>Tier One COPPER</p>
                                    <p>Tier Two BRONZE</p>
                                    <p>Tier Three SILVER</p>
                                    <p>Tier Four GOLD</p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs="12" md="6">
                        <Row className="justify-content-center">
                            <Col xs="11">
                                <Row className="FeaturesLowerTabContainer">
                                    <h4 style={{ fontWeight: 'bold' }}>Guaranteed Allocations</h4>
                                    <p>Every tier is guaranteed allocation excluding lottery tier which is lottery based allocation. In guaranteed allocation, Copper tier members need to complete the whitelist requirements (Like, Comment & Retweet).</p>
                                    <p>In round 2, the unsold tokens from the first round are made available First Come First Serve (FCFS) basis, only to guaranteed tiers (Bronze and above). These members can purchase an additional amount that is determined by a tier-based formula. This round is open until all tokens are sold, typically lasting for only a few minutes.</p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="6" className="mt-5 mt-md-0">
                        <Row className="justify-content-center">
                            <Col xs="11">
                                <Row className="FeaturesLowerTabContainer">
                                    <h4 style={{ fontWeight: 'bold' }}>ALLOCATIONS:</h4>
                                    <p>TIER ONE COPPER:</p>
                                    <p>Participants in copper tier will have to stake $1000 Polkadot and pool weight is 1x.</p>
                                    <p>TIER TWO BRONZE:</p>
                                    <p>Participants in bronze tier will have to stake $3000 Polkadot and pool weight is 3x.</p>
                                    <p>TIER THREE SILVER:</p>
                                    <p>Participants in silver tier will have to stake $6000 Polkadot and pool weight is 6x.</p>
                                    <p>TIER FOUR GOLD:</p>
                                    <p>Participants in gold tier will have to stake $8000 Polkadot and pool weight is 8x.</p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Features;

