import React, { Component } from "react";
import { Col, Row, Button } from "reactstrap";
import { GOOGLE_IDO_FORM } from "../../Common/URLs";
import { Translate } from "../../i18n/localization";
import "./styles.css";
import Img from "../../Assets/globe.png"

const ApplyNow = () => {
    const openTab = (link: string) => {
        window.open(link, '_blank')?.focus();
    }
    return <Row>
        <Col className="ApplyNowMainContainer">
            <Row>
                <Col className="ApplyNowMainHeading" >
                    {Translate("ApplyNow.MainHeading")}
                </Col>
            </Row>
            <Row >
                <Col className="ApplyNowContent">
                    {Translate("ApplyNow.SubHeading")[0]}<br />
                    {Translate("ApplyNow.SubHeading")[1]}
                </Col>
            </Row>
            <Row className="ApplyNowButtonRow">

                <img src={Img} style={{ width: "50%"  }} />
                <Col lg={3} md={6} xs={9} className="imgs">

                    <Button className="ApplyNowButton"
                        onClick={() => openTab(GOOGLE_IDO_FORM)}
                    >
                        {Translate("Button.Button")}
                    </Button>
                </Col>
            </Row>
        </Col>
    </Row>
}


export default ApplyNow;

