import React from "react";
import Check from "../../Assets/checkpointChecked.png";
import "./styles.css";

interface IProps {
    checked: boolean
    mainHeading: string
    subHeading: string
    pspadHeading?: any
    pspad?: any
}

const StakingCheckpoint = (props: IProps) => {
    return <div className="StakingCheckpointMainContainer">
        <div style={{ wordWrap: "break-word" }} >
            <div className="StakingCheckpointHeading">{props.mainHeading}</div>
            <div className="mt-2 StakingCheckpointSubHeading">{props.subHeading}</div>
            <div className="mt-2 StakingCheckpointSubHeading">{props.pspadHeading}</div>
            <div className="mt-1 StakingCheckpointSubHeading">{props.pspad}</div>

        </div>
        {props.checked && <div className="StakingCheckpointIcon">
            <img src={Check} alt="Staking Checkpoint" />
        </div>}

    </div>
}

export default StakingCheckpoint;
