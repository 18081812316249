import React, { Component } from "react";
import { NavLink } from "reactstrap";
import { Col, Row } from "reactstrap";
import Dot from "../../Assets/dot.png";
import Telegram from "../../Assets/telegram.png";
import FooterLogo from "../../Assets/pspad1.png";
import Reddit from "../../Assets/Redditicon.png";
import SM_Icon3 from "../../Assets/sm_Icon3.png";
import Twitter from "../../Assets/twitter.png";
import { Facebook_Page, Medium_Page, Reddit_Page, Twitter_Page, Telegram_Page } from "../../Common/URLs";
import FooterIcon from "../../Components/FooterIcon";
import { Translate } from "../../i18n/localization";
import * as RouteNames from "../../Routes/routeNames";
import "./styles.css";


class Footer extends Component {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <Row className="FooterContainer" >
                <Col className="FooterLogo mt-2" xl={2} lg={3} >
                    <img src={FooterLogo} alt={Translate("Footer.LogoAlt")} style={{ width: "auto", height: "auto" }} />
                    <p className="mt-2 FooterLogoText" >{Translate("Footer.LogoText")}</p>
                </Col>
                <Col className="FooterTermsContainer" xl={7} lg={6} style={{ zIndex: 999 }}>
                    <NavLink className="TermsText" href={`/${RouteNames.PRIVACY}`}>{Translate("Footer.PrivacyText")}</NavLink>
                    <div>
                        <img src={Dot} alt={Translate("Footer.Dot")} style={{ width: "1vh", height: "auto", margin: "2vw" }} />
                    </div>
                    <NavLink className="CookiesText" href={`/${RouteNames.COOKIES}`}>{Translate("Footer.CookiesText")}</NavLink>
                    <div>
                        <img src={Dot} alt={Translate("Footer.Dot")} style={{ width: "1vh", height: "auto", margin: "2vw" }} />
                    </div>
                    <NavLink className="CookiesText" href="https://drive.google.com/file/d/186BHWlVg8KPoqweCnE_ZftpPdKdNQeD6/view?usp=sharing">{Translate("Footer.whitePaper")}</NavLink>

                </Col>
                <Col className="FooterMediaContainer" lg={3}>
                    <Col className="FooterMediaText" >
                        {Translate("Footer.StayConnected")}
                    </Col>
                    <Col className="FooterMediaIcons" >

                        {/* <FooterIcon imageSource={Reddit} route={Reddit_Page} style={{ width: "105%", marginRight: "21px" }} /> */}
                        <FooterIcon imageSource={Twitter} route={Twitter_Page} style={{ width: "97%" }} />
                        <FooterIcon imageSource={Telegram} route={Telegram_Page} style={{ width: "97%" }} />
                        <FooterIcon imageSource={SM_Icon3} route={Medium_Page} style={{ width: "97%" }} />

                    </Col>
                </Col>
            </Row>
        )
    }
}

export default Footer;

