import React from "react";
import { Col, Row } from "reactstrap";
import SuccessIcon from "../../Assets/checkpoint.png";
import { Translate } from "../../i18n/localization";
import { BSC_Scan } from "../../Common/URLs";
import "./styles.css";

interface IProps {
    txHash: string
}

const ConfirmationContainer = (props: IProps) => {

    const viewTx = () => {
        window.open(`${BSC_Scan}/${props.txHash}`, '_blank')?.focus();
    }

    return <Row className="StakingAmountContainer">
        <Col md={10} className="offset-md-1 d-flex flex-column align-items-center justify-content-between">
            <div className="UnStakingSuccessBox">
                <div className="iconClass"><img src={SuccessIcon} alt="Success Icon" /></div>
            </div>
            <div className="PreAuthHeading">{Translate("UnStaking.ConfirmationHeading1")}</div>
            <div className="PreAuthSubHeading">{Translate("UnStaking.ConfirmationContent1")}</div>
            <div className="PreAuthSubHeading">{Translate("UnStaking.ConfirmationContent2")}</div>
            <div className="UnstakingConfirmationLink" onClick={() => viewTx()} >
                {Translate("UnStaking.ConfirmationContent3")}
            </div>
        </Col>
    </Row>
}

export default ConfirmationContainer;
