import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import Web3 from "web3";
import { PSPAD, ZUSD } from "../../Common/TokenConfig";
import StakingStatus from "../../Components/StakingStatus";
import { toByte32, toEth, toWei } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";

const StakingStatusContainer = () => {
    const [numberOfStakes, setNumberOfStakes] = useState<number>(0)
    const [totalStakes, setTotalStakes] = useState<any>(0)
    const [APY, setAPY] = useState<string>('')

    const Web3Instance = SingletonWeb3Instance.getInstance()
    const { account } = useWeb3React();
    const stakingContractInstance = Web3Instance.stakingContractInstance
    const getBalanceFromWeb3 = async () => {
        if (account !== (null || undefined)) {
            const totalStakers = await stakingContractInstance.methods.totalStakers().call({ from: account })
            const apyRate = await stakingContractInstance.methods.getRewardRate().call({ from: account })
            const TotalZUSDStaked = await stakingContractInstance.methods.coinStakesCount(ZUSD).call({ from: account })
            const TotalPSPADStaked = await stakingContractInstance.methods.coinStakesCount(PSPAD).call({ from: account })
            const Sum = TotalZUSDStaked + TotalPSPADStaked
            setNumberOfStakes(totalStakers);
            setTotalStakes(toEth(TotalZUSDStaked));
            setAPY(toEth(apyRate) + "%")
        }
    }
    useEffect(() => {
        getBalanceFromWeb3()
    }, [account])
    return <Row>
        <StakingStatus heading={Translate("UnStaking.StakingStatusHeading1")} content={numberOfStakes} />
        <StakingStatus heading={Translate("UnStaking.StakingStatusHeading2")} content={totalStakes} paddingLeft paddingRight />
        <StakingStatus heading={Translate("UnStaking.StakingStatusHeading3")} content={APY} />
    </Row>
}
export default StakingStatusContainer;
