import React from "react";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import { Translate } from "../../i18n/localization";
import HomeButton from "../HomeButton";
import * as RouteNames from "../../Routes/routeNames";

import "./styles.css";
import { useWeb3React } from "@web3-react/core";
import { SingletonWeb3Instance } from "../../Web3";

interface IProps {
    stakingStatus: number
    nextDisabled: boolean
    modalHandler: any
}

const NextButtonComponent = (props: IProps) => {
    const { active, account } = useWeb3React();
    const Web3Instance = SingletonWeb3Instance.getInstance();
    const stakingContractInstance = Web3Instance.stakingContractInstance
    const navigate = useNavigate();
    const NextHandler = async () => {

        if (props.stakingStatus > 4) {
            navigate(`/${RouteNames.STAKING}/${RouteNames.STEP_1}`)
        }
        else {
            const respp = await stakingContractInstance.methods.getActiveRoundName().call({ from: account });

            const balence = await stakingContractInstance.methods.userBalence(respp, account).call({ from: account });

            if (props.stakingStatus == 2 && props.modalHandler && balence.length <= 0) {
                props.modalHandler()
            }
            else {
                navigate(`./step_${props.stakingStatus + 1}`)
                console.log()
            }
        }
    }
    const PreviousHandler = () => {
        if (props.stakingStatus < 2) {
            navigate(`/${RouteNames.STAKING}/${RouteNames.STEP_1}`)
        }
        else {
            navigate(`./step_${props.stakingStatus - 1}`)
        }
    }
    return <Row className="NextButtonMainContianer">
        <Col lg={3} sm={5} >
            <HomeButton title={Translate("Button.Previous")}
                disabled={props.stakingStatus > 3}
                onClick={() => {
                    PreviousHandler()
                }} />
        </Col>
        <Col lg={3} sm={5} >
            <HomeButton title={Translate("Button.Next")}
                disabled={props.nextDisabled}
                onClick={() => {
                    NextHandler()
                }} />
        </Col>

    </Row>
}

export default NextButtonComponent;
