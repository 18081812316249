import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Web3 from "web3";
import { PSPAD, ZUSD } from "../../Common/TokenConfig";
import { toByte32, toByteN, toEth, toWei } from "../../Handlers/converters";
import { SingletonWeb3Instance } from "../../Web3";
import HomeButton from "../HomeButton";

interface IProps {
    openModal: boolean;
    setOpenModal: Function

}
const ProjectModal = (props: IProps) => {
    const { account } = useWeb3React();
    const [tokenName, settokenName] = useState<string>('');
    const [tokenSymbol, setTokenSymbol] = useState<string>('');
    const [projectDescription, setprojectDescription] = useState<string>('');
    const [projectStatus, setprojectStatus] = useState<string>('');
    const [swapToken, setswapToken] = useState<any>('');
    const [swapRate, setswapRate] = useState<string>('');
    const [marketCap, setmarketCap] = useState<string>('');
    const [access, setaccess] = useState<string>('');
    const [vestingdays, setvestingdays] = useState<string>('');
    const [firstReleaseAmountPercentage, setfirstReleaseAmountPercentage] = useState<string>('');
    const [monthlyReleaseAmountPercentage, setmonthlyReleaseAmountPercentage] = useState<string>('');
    const [preSeedRoundOpnenDate, setpreSeedRoundOpnenDate] = useState<any>('');
    const [preSeedRoundClosedDate, setpreSeedRoundClosedDate] = useState<any>('');
    const [seedRoundOpenDate, setseedRoundOpenDate] = useState<any>('');
    const [seedRoundClosedDated, setseedRoundClosedDated] = useState<any>('');
    const [publicRoundOpenDate, setpublicRoundOpenDate] = useState<any>('');
    const [publicRoundClosedDate, setpublicRoundClosedDate] = useState<any>('');

    const Web3Instance = SingletonWeb3Instance.getInstance();
    const projectContractInstance = Web3Instance.projectContractInstance;
    const submitHandler = async () => {
        const currentDate = new Date();
        if (
            // currentDate.getTime() < new Date(preSeedRoundOpnenDate).getTime() ||
            // currentDate.getTime() < new Date(seedRoundOpenDate).getTime() &&
            // currentDate.getTime() < new Date(publicRoundOpenDate).getTime() &&
            // new Date(publicRoundOpenDate).getTime() < new Date(publicRoundClosedDate).getTime() &&
            // new Date(preSeedRoundOpnenDate).getTime() < new Date(preSeedRoundClosedDate).getTime() &&
            // new Date(seedRoundOpenDate).getTime() < new Date(seedRoundClosedDated).getTime()
            //
            new Date(preSeedRoundClosedDate).getTime() < new Date(seedRoundOpenDate).getTime() &&
            new Date(seedRoundClosedDated).getTime() < new Date(publicRoundOpenDate).getTime()
            // new Date(seedRoundOpenDate).getTime() < new Date(seedRoundClosedDated).getTime()


        ) {
            const projectdetails = {
                tokenName: toByteN((tokenName), 32),
                tokenSymbol: toByteN((tokenSymbol), 32),
                projectDescription: toByteN((projectDescription), 32),
                swapToken,
                cap: toWei(marketCap),
                swapRate: Web3.utils.toWei(swapRate),
                access: toByteN((access), 32),
                vestingDays: Web3.utils.toWei(vestingdays),
                firstReleaseAmountPercentage: Web3.utils.toWei(firstReleaseAmountPercentage),
                monthlyReleaseAmountPercentage: Web3.utils.toWei(monthlyReleaseAmountPercentage),
                preSeedRoundOpnenDate: (new Date(preSeedRoundOpnenDate).getTime() / 1000),
                preSeedRoundClosedDate: (new Date(preSeedRoundClosedDate).getTime() / 1000),
                seedRoundOpenDate: (new Date(seedRoundOpenDate).getTime() / 1000),
                seedRoundClosedDated: (new Date(seedRoundClosedDated).getTime() / 1000),
                publicRoundOpenDate: (new Date(publicRoundOpenDate).getTime() / 1000),
                publicRoundClosedDate: (new Date(publicRoundClosedDate).getTime() / 1000)
            };
            try {
                await projectContractInstance.methods.addProject(projectdetails).send({ from: account, gasLimit: 9999999 });
            }
            catch (err) {
                alert(err)
            }
        }
        else {
            alert('Invalid Dates')
        }
        props.setOpenModal(false)
    }
    return (
        <Modal
            isOpen={props.openModal}
            centered={true}
            backdrop={true}
            size="md"
        >
            <ModalHeader className="withdrawModalContainer">
                <h1>ADD IDO</h1>
            </ModalHeader>
            <ModalBody className="withdrawModalContainer">
                <Row style={{ display: 'flex' }}>
                    <p>
                        Token Name
                    </p>
                    <Input
                        className="inputFields"
                        value={tokenName}
                        onChange={(e) => settokenName(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Token Symbol
                    </p>
                    <Input
                        className="inputFields"
                        value={tokenSymbol}
                        onChange={(e) => setTokenSymbol(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Project Description
                    </p>
                    <Input
                        className="inputFields"
                        value={projectDescription}
                        onChange={(e) => setprojectDescription(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Swap rate
                    </p>
                    <Input
                        className="inputFields"
                        type="number"
                        value={swapRate}
                        onChange={(e) => setswapRate(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Swap Token
                    </p>
                    <Input
                        type="select"
                        className="inputFields"
                        value={swapToken}
                        onChange={(e) => { setswapToken(e.target.value) }}
                    >
                        <option value="">Select</option>
                        <option value={ZUSD}>ZUSD</option>
                        <option value={PSPAD}>PSPAD</option>
                    </Input>
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Market cap
                    </p>
                    <Input
                        className="inputFields"
                        type="number"
                        value={marketCap}
                        onChange={(e) => setmarketCap(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Access
                    </p>
                    <Input
                        type="select"
                        className="inputFields"
                        value={access}
                        onChange={(e) => { setaccess(e.target.value) }}

                    >
                        <option value="">Select</option>
                        <option value="Private">Private</option>
                        <option value="Public">Public</option>
                    </Input>
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Vesting days
                    </p>
                    <Input
                        className="inputFields"
                        type="number"
                        value={vestingdays}
                        onChange={(e) => setvestingdays(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        First release amount percentage
                    </p>
                    <Input
                        className="inputFields"
                        type="number"
                        value={firstReleaseAmountPercentage}
                        onChange={(e) => setfirstReleaseAmountPercentage(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Monthly release amount percentage
                    </p>
                    <Input
                        className="inputFields"
                        type="number"
                        value={monthlyReleaseAmountPercentage}
                        onChange={(e) => setmonthlyReleaseAmountPercentage(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Preseed round Opens (Time & Date)
                    </p>
                    <Input
                        className="inputFields"
                        type="datetime-local"
                        value={preSeedRoundOpnenDate}
                        onChange={(e) => setpreSeedRoundOpnenDate(e.target.value)}

                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Preseed round Closes (Time & Date)
                    </p>
                    <Input
                        className="inputFields"
                        type="datetime-local"
                        value={preSeedRoundClosedDate}
                        onChange={(e) => setpreSeedRoundClosedDate(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Seed round Opens (Time & Date)

                    </p>
                    <Input
                        className="inputFields"
                        type="datetime-local"
                        value={seedRoundOpenDate}
                        onChange={(e) => setseedRoundOpenDate(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Seed round Closes (Time & Date)
                    </p>
                    <Input
                        className="inputFields"
                        type="datetime-local"
                        value={seedRoundClosedDated}
                        onChange={(e) => setseedRoundClosedDated(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        public round Open (Time & Date)
                    </p>
                    <Input
                        className="inputFields"
                        type="datetime-local"
                        value={publicRoundOpenDate}
                        onChange={(e) => setpublicRoundOpenDate(e.target.value)}
                    />
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p>
                        Public round Closes (Time & Date)
                    </p>
                    <Input
                        className="inputFields"
                        type="datetime-local"
                        value={publicRoundClosedDate}
                        onChange={(e) => setpublicRoundClosedDate(e.target.value)}
                    />
                </Row>
            </ModalBody>
            <ModalFooter className="withdrawModalContainer">
                <HomeButton title="Close" onClick={() => props.setOpenModal(false)} />
                <HomeButton title="Save" onClick={() => submitHandler()} />

            </ModalFooter>
        </Modal>

    )
}
export default ProjectModal;