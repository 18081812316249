import { Navigate, useNavigate } from "react-router";
import { useWeb3React } from "@web3-react/core";
import { SingletonWeb3Instance } from "../../Web3";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    Button,
    Col,
    Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, Row
} from 'reactstrap';
import Logo from "../../Assets/pspad1.png";
import { injected } from "../../Common/InjectedChainIds";
import { Translate } from "../../i18n/localization";
import * as RouteNames from "../../Routes/routeNames";
import DisconnectWalletModal from "./DisconnectWallet";
import ErrorModal from "./ErrorModal";
import WalletsModal from "./WalletsModal";
import "./styles.css";
import { isMobile } from 'react-device-detect';
// import { SingletonWeb3Instance } from "../../Web3";

const Header = () => {
    const [openErrorModal, setErrorModal] = useState<boolean>(false);
    const [ErrorText, setErrorText] = useState<string>("");
    const [openWalletsModal, setWalletsModal] = useState<boolean>(false);
    // const [ChooseAdminModal, setAdminModal] = useState<boolean>(false);
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const { active, account, activate, deactivate } = useWeb3React()
    // const [account]
    const stakingContractInstance = Web3Instance.stakingContractInstance
    const navigate = useNavigate();
    const [openDWModal, setDWModal] = useState<boolean>(false);
    const [toggleNav, setToggleNav] = useState(false)
    const [accountAddress, setAccountAddress] = useState<string | null | undefined>();

    useEffect(() => {
        setAccountAddress(account);
    }, [account])

    async function connect() {
        if (!window.ethereum) {
            setErrorText("Please Use Browser with MetaMask Installed")
            setErrorModal(true)
        }
        if (active) {
            setDWModal(true)
        }

        if (!active) {
            localStorage.setItem('key', 'true')
            await activate(injected)
            await verify()

        }
    }
    
    async function verify() 
    {   
        const currentAccount = await injected.getAccount();
        const owner = await stakingContractInstance.methods.owner().call({from: currentAccount});
        if(owner.toLocaleLowerCase() == currentAccount?.toLocaleLowerCase()){
            navigate(`/${RouteNames.ADMIN}`)
        }

    }


    function checkDevice() {
        if (isMobile && !window.ethereum) {
            setWalletsModal(true)
            return
        }
        connect()
    }

    return (
        < >
            <Row>
                <Col>
                    <div style={{ borderBottom: "1px solid rgba(144, 114, 170, 0.2)" }} >
                        <Navbar dark expand="lg">
                            <NavbarBrand onClick={() => navigate(`/`)} className="HeaderNavBarBrand" ><img src={Logo} alt="HeaderLogo" style={{ width: '100%', height: "auto" }} /></NavbarBrand>
                            <NavbarToggler onClick={() => setToggleNav(prevState => !prevState)} style={{ width: "15vw", height: "auto" }} />
                            <Collapse id="basic-navbar-nav" isOpen={toggleNav} navbar className="HeaderCollapseContainer">
                                <Nav className="HeaderNavContainer">
                                    <NavItem className="HeaderNavItem" >
                                        <NavLink className="HeaderNavLink" to={`/${RouteNames.HOW_IT_WORKS}`}>{Translate("Header.HowItWorks")}</NavLink>
                                    </NavItem>
                                    <NavItem className="HeaderNavItem" >
                                        <NavLink className="HeaderNavLink" to={`/${RouteNames.TOKEN_INFO}`}>{Translate("Header.TokenInfo")}</NavLink>
                                    </NavItem>
                                    <NavItem className="HeaderNavItem" >
                                        <NavLink className="HeaderNavLink" to={`/${RouteNames.FEATURES}`}>{Translate("Header.Feature")}</NavLink>
                                    </NavItem>
                                    <NavItem className="HeaderNavItem" >
                                        <NavLink className="HeaderNavLink" to={`/${RouteNames.ABOUT_US}`}>{Translate("Header.AboutUs")}</NavLink>
                                    </NavItem>
                                    <NavItem className="HeaderNavItem" >
                                        <NavLink className="HeaderNavLink" to={`/${RouteNames.SOCIAL_MEDIA}`}>{Translate("Header.SocialMedia")}</NavLink>
                                    </NavItem>
                                </Nav>
                                <Button color="transparent" className="HeaderConnectWalletContainer" onClick={checkDevice} >
                                    {(active) && (<span style={{ color: 'white' }}>{`${account?.substring(0, 5)} - ${account?.substring(account?.length - 5)}`}</span>) ||
                                        (<span style={{ color: 'white' }}>{Translate("Header.Connect")} {Translate("Header.Wallet")}</span>)}
                                </Button>
                            </Collapse>
                        </Navbar>
                    </div>
                </Col>
            </Row>
            <div>
                <ErrorModal openModal={openErrorModal} text={ErrorText} setOpenModal={() => setErrorModal(false)} />
            </div>
            <div>
                <DisconnectWalletModal openModal={openDWModal} setOpenModal={() => setDWModal(false)} disconnectHandler={deactivate} />
            </div>
            <div>
                <WalletsModal openModal={openWalletsModal} setOpenModal={() => setWalletsModal(false)} />
            </div>
            {/* <div>
                <AdminsModal 
                    openModal={ChooseAdminModal} 
                    onError={(error: string) => {
                        setErrorText(error);
                        setErrorModal(true)
                    }
                    }
                    setOpenModal={() => setAdminModal(false)}
                />
            </div> */}
        </>
    )
}


export default Header;
