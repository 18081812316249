import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import HomeButton from "../../Components/HomeButton";
import { Translate } from "../../i18n/localization";
import * as RouteNames from "../../Routes/routeNames";
import "./styles.css";

const HomeButtonRow = () => {
    
    const currentItem = localStorage.getItem("currentItem");
    const [state, setState] = useState({
        first: (currentItem !== '2') && (currentItem !== '3') ? true : false,
        second: currentItem == '2' ? true : false,
        third: currentItem == '3' ? true : false
    })
    const navigate = useNavigate();

    return <Row className="align-items-center justify-content-lg-start justify-content-center mt-2 mb-5">
        <Col lg={3} md={4} sm={7} className="HomeButtonRowMargin" >
            <HomeButton isFocused={state.first} title={Translate("Button.HomeButton1")} style={{ margin: "0", }} onClick={async () => {
                navigate(`/${RouteNames.STAKING}/${RouteNames.STEP_1}`)
                // setState({...defaultState, first: true})
                await localStorage.setItem('currentItem', '1');
            }} />
        </Col>
        <Col lg={3} md={4} sm={7} className="HomeButtonRowMargin">
            <HomeButton isFocused={state.second} title={Translate("Button.HomeButton2")} style={{ margin: "0" }} onClick={async () => {
                navigate(`/${RouteNames.UNSTAKING}/${RouteNames.STEP_1}`)
                // setState({...defaultState, second: true})
                await localStorage.setItem('currentItem', '2');
            }} />
        </Col>
        <Col lg={3} md={4} sm={7} className="HomeButtonRowMargin">
            <HomeButton isFocused={state.third} title={Translate("Button.HomeButton3")} style={{ margin: "0" }} onClick={async () => {
                navigate(`/${RouteNames.PROJECTS}/${RouteNames.STEP_1}`)
                // setState({...defaultState, third: true})
                await localStorage.setItem('currentItem', '3');
            }} />
        </Col>
        <Col lg={1} className="d-none d-md-block" />
    </Row>
}

export default HomeButtonRow;

