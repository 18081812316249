import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils'
import { STAKING_CONTRACT_ADDRESS, EventStartBlock, PROJECT } from "../Common/TokenConfig";
import { abi as stakingJson } from "../Common/Contracts/build/Staking.json";
import { abi as tokenJson } from "../Common/Contracts/build/PSP.json";
import { abi as whitelistJson } from "../Common/Contracts/build/WhiteList.json";
import { abi as ProjectJson } from "../Common/Contracts/build/Project.json";

import { Interface } from 'readline';

declare global {
    interface Window {
        web3: Web3;
        ethereum: any
    }

    interface IWeb3Instance {
        tokenContractInstance: any;
        stakingContractInstance: any;
        // whiteAddressContractInstance: any;
        web3: Web3 | undefined;
    }
}

class Web3Instance implements IWeb3Instance {
    private StakingABI: any = stakingJson;
    private TokenABI: any = tokenJson;
    private ProjectABI: any = ProjectJson;
    private WhiteListJson: any = whitelistJson;
    public tokenContractInstance: any;
    public stakingContractInstance: any;
    public projectContractInstance: any;
    // public whiteAddressContractInstance: any;
    public web3: Web3 | undefined;

    constructor() {
        if (!window.ethereum) {
            console.info('Web3 not found');
            return;
        }

        window.ethereum.enable();
        this.web3 = new Web3(window.web3!.currentProvider);
        this.stakingContractInstance = new this.web3!.eth.Contract(this.StakingABI, STAKING_CONTRACT_ADDRESS);
        this.projectContractInstance = new this.web3!.eth.Contract(this.ProjectABI, PROJECT);

    }

    getTokenContract(address: string) {
        return new this.web3!.eth.Contract(this.TokenABI, address);
    }
    getWhiteListContract(address: string) {
        return new this.web3!.eth.Contract(this.WhiteListJson, address);
    }
}

export const SingletonWeb3Instance = (function () {
    let instance: Web3Instance;

    function createInstance() {
        var classObj = new Web3Instance();
        return classObj;
    }

    async function isOwner() {
        if (instance == null) {
            instance = createInstance();
        }
        const walletAddress = window.ethereum.selectedAddress;
        var contractOwner = await instance.stakingContractInstance.methods.owner().call();
        return walletAddress?.toLowerCase() == contractOwner.toLowerCase();
    }

    function subscribeTx(contract: string, topicFilter: (string | string[] | null)[], onData: { (data: any): void }) {
        if (instance == null) {
            instance = createInstance();
        }
        if (instance.web3 == undefined) {
            throw 'web3 not available';
        }

        const subscription = instance.web3!.eth.subscribe('logs', {
            fromBlock: EventStartBlock,
            address: contract,
            topics: topicFilter
        }, function (error, result) {
            if (!error)
                console.log(result);
        }).on("data", function (data: any) {
            onData(data);
        });
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
        subscribeTransaction: function (contractAddress: string, topics: (string | string[] | null)[], onSuccess: { (data: any): void }) {
            subscribeTx(contractAddress, topics, onSuccess);
        },
        isOwner: function () {
            return isOwner();
        },
    };
})();

