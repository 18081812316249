import React from "react";
import { useRoutes } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "../App.css";
import Footer from "../Containers/Footer";
import Header from "../Containers/Header";
import HomeScreen from "../Containers/HomeScreen";
import Staking from "../Containers/Staking";
import UnStaking from "../Containers/UnStaking";
import Projects from "../Containers/Projects";
import AboutUs from "../Containers/AboutUs";
import SocialMedia from "../Containers/SocialMedia";
import TokenInfo from "../Containers/TokenInfo";
import HowItWorks from "../Containers/HowItWorks";
import Privacy from "../Containers/Privacy";
import ProjectInfo from '../Components/ProjectsView/ProjectsInfo'
import Cookies from "../Containers/Cookies";
import Features from "../Containers/Features";
import AdminPage from '../Containers/Admin/index';

import * as RouteNames from "./routeNames";

const RoutesClass = () => {

  const routes = useRoutes([
    { path: "/", element: <HomeScreen /> },
    { path: `${RouteNames.HOME}`, element: <HomeScreen /> },
    { path: `${RouteNames.HOW_IT_WORKS}`, element: <HowItWorks /> },
    { path: `${RouteNames.TOKEN_INFO}`, element: <TokenInfo /> },
    { path: `${RouteNames.STAKING}/*`, element: <Staking /> },
    { path: `${RouteNames.UNSTAKING}/*`, element: <UnStaking /> },
    { path: `${RouteNames.PROJECTS}/*`, element: <Projects /> },
    { path: `${RouteNames.ABOUT_US}`, element: <AboutUs /> },
    { path: `${RouteNames.SOCIAL_MEDIA}`, element: <SocialMedia /> },
    { path: `${RouteNames.PRIVACY}`, element: <Privacy /> },
    { path: `${RouteNames.COOKIES}`, element: <Cookies /> },
    { path: `${RouteNames.DATASCREEN}`, element: <ProjectInfo /> },
    { path: `${RouteNames.FEATURES}`, element: <Features /> },
    { path: `${RouteNames.ADMIN}`, element: <AdminPage /> }

  ]);
  return <Container fluid className="offset-lg-1 col-lg-10">
    <Header />
    <Row >
      <Col>
        {routes}
      </Col>
    </Row>
    <Footer />
  </Container>;

}

export default RoutesClass;


