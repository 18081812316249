import React from "react";
import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import "./styles.css";

interface IProps {
    placeholder: string
    groupText: string
    value: string
    onClick: Function
    onChangeHandler: Function
}

const AmountInput = (props: IProps) => {
    return <InputGroup className="AmountInputContainer">
        <Input placeholder={props.placeholder}
            value={props.value}
            onChange={(event: any) => props.onChangeHandler(event.target.value)}
        />
        <InputGroupText className="p-0">
            <Button className="AmountInputButton" onClick={() => props.onClick()} >
                {props.groupText}
            </Button>
        </InputGroupText>
    </InputGroup>
}

export default AmountInput;
