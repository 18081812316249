import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { toByteN, toWei } from "../../Handlers/converters";
import HomeButton from "../HomeButton";
import { SingletonWeb3Instance } from "../../Web3";
import Home from "../../Containers/Home";
import Web3 from "web3";


interface IProps {
    openModal: boolean;
    setOpenModal: Function

}

const AddPackages = (props: IProps) => {
    const { account } = useWeb3React();
    const [packagName, setPackageName] = useState<any>('');
    const [amount, setAmount] = useState<any>();
    const [poolWeight, setPoolWeight] = useState<string>('');
    const [maxUser, setMaxUser] = useState<string>('');
    const [roundName, setRoundName] = useState<string>('');
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const stakingContractPlaceholder = Web3Instance.stakingContractInstance
    const stakingContractInstance = Web3Instance.stakingContractInstance;
    let defaultRoundValues: string[] = [];
    const [roundList, setRoundList] = useState(defaultRoundValues);

    useEffect(() => {
        getRounds()
    }, []);

    const addPackages = {
        name: toByteN(packagName, 32),
        stakingAmount: amount && Web3.utils.toWei(amount, 'ether'),
        poolWeight: poolWeight,
        maxUsers: maxUser
    }

    const getRounds = async () => {

        const currentRoundName = await stakingContractInstance.methods.getActiveRoundName().call({ from: account })

        const roundNames = await stakingContractInstance.methods.getRoundNames().call({ from: account })        
        const rounds = roundNames.map((key: string, value: string) => { return Web3.utils.toUtf8(key); });
        setRoundList(rounds);
    }

    const SubmitHandler = async () => {
        try {
            const resp = await stakingContractPlaceholder.methods.addRoundPackage(toByteN(roundName, 32), addPackages).send({ from: account })

            props.setOpenModal(false)
        }
        catch {
            props.setOpenModal(false)
        }
    }
    return <Modal
        isOpen={props.openModal}
        centered={true}
        backdrop={true}
        size="sm"
    >
        <ModalHeader className="withdrawModalContainer">
            <h1>
                Add Packages
            </h1>
        </ModalHeader>
        <ModalBody className="withdrawModalContainer">
            <Row style={{ display: 'flex' }}>
                <p>
                    Round Name
                </p>
                <Input
                    type="select"
                    className="inputFields"
                    value={roundName}
                    onChange={(e) => { setRoundName(e.target.value) }}

                >
                    {roundList.map((key, value) => <option key={key} value={(key)}>{key}</option>)}

                </Input>
            </Row>
            <Row style={{ display: 'flex' }}>
                <p>
                    Package Name
                </p>
                <Input
                    className="inputFields"
                    value={packagName}
                    onChange={(e) => setPackageName(e.target.value)}
                />
            </Row>
            <Row style={{ display: 'flex' }}>
                <p>
                    Amount
                </p>
                <Input
                    className="inputFields"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
            </Row>
            <Row style={{ display: 'flex' }}>
                <p>
                    Pool Weight
                </p>
                <Input
                    className="inputFields"
                    value={poolWeight}
                    onChange={(e) => setPoolWeight(e.target.value)}
                />
            </Row>
            <Row style={{ display: 'flex' }}>
                <p>
                    Max User
                </p>
                <Input
                    className="inputFields"
                    value={maxUser}
                    onChange={(e) => setMaxUser(e.target.value)}
                />
            </Row>
        </ModalBody>
        <ModalFooter className="withdrawModalContainer">
            <HomeButton
                title="Close"
                onClick={() => props.setOpenModal(false)}
            />

            <HomeButton
                title="Submit"
                onClick={() => SubmitHandler()}
            />

        </ModalFooter>
    </Modal>
}

export default AddPackages;
