export const STAKING_CONTRACT_ADDRESS = "0xc4D533f5Bf536f3B709884F8CEAf5582f5D9Ba50";
// export const WHITELIST = "0xC864BaA27315338013b0e1CAea3B4257f3DEB80F";
export const PROJECT = "0x108E9c7199F3C7A04b679Ff3935f20e9A02cb420";
export const PSPAD = "0x8808a566E1aA75Dc0bC31bf94854635a792fAD30";
export const ZUSD = "0x3c0169eDad4251588F4734796bE553aD54AA6d5D";
//
export const MetaVPad = "0xc4A3579c6296AC45f49659a7921639e5911Cb033";
export const PulsePad = "0xB3e9eF8feB6bFf2e82026d32e4Cf4A5dcD9B375a";
export const Lovelace = "0x097f82D520AA53E3c86C53A60782Ecca244834c1";
export const EventStartBlock = 1;//1776353;
//Metamask  
export const INITIALIZING = "initializing";
export const UNAVAILABLE = "unavailable";
export const NOT_CONNECTED = "notConnected";
export const CONNECTING = "connecting";
export const CONNECTED = "connected";

// 0x5afbF88712aBDB4fCCf45Ef0d386F1fE673d95fe  61218295 0x6Be02d1d3665660d22FF9624b7BE0551ee1Ac91b
//0x6905C179BaD8c07E967EB170082B1237b70865c6 Original Contract