import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import {
    Route,
    Routes, useLocation
} from "react-router-dom";
import { Col, Row } from "reactstrap";
import AmountToStake from '../../Assets/amountToStake.png';
import AmountToStakeChecked from '../../Assets/amountToStakeChecked.png';
import Checkpoint from '../../Assets/checkpoint.png';
import CheckpointChecked from '../../Assets/checkpointChecked.png';
import Confirm from '../../Assets/confirm.png';
import Confirmation from '../../Assets/confirmation.png';
import ConfirmationChecked from '../../Assets/confirmationChecked.png';
import ConfirmChecked from '../../Assets/confirmChecked.png';
import PreAuth from '../../Assets/preAuth.png';
import PreAuthChecked from '../../Assets/preAuthChecked.png';
import { IPackage } from "../../Common/Models";
import HomeButtonRow from "../../Components/HomeButtonRow";
import NextButtonComponent from "../../Components/NextButtonComponent";
import StakingStatsContainer2 from "../../Components/StakingStatsContainer2";
import StakingStatusContainer from "../../Components/StakingStatusContainer";
import StakingStatusIcon from "../../Components/StakingStausIcons";
import * as StakingStatuses from "../../Constants/stakingStatuses";
import { Translate } from "../../i18n/localization";
import * as RouteNames from "../../Routes/routeNames";
import "./styles.css";
import UnstakingAmountContainer from "./UnStakingAmountContainer";
import UnStakingCheckpointsContainer from "./UnStakingCheckpointsContainer";
import ConfirmationContainer from "./UnStakingConfirmationContainer";
import UnStakingInitialize from "./UnStakingInitialize";
import UnStakingWarningContainer from "./UnStakingWarningContainer";

const UnStaking = () => {

    const [stakingStatus, setStakingStatus] = useState<number>(1)
    const [nextDisabled, setNextDisabled] = useState<boolean>(false)
    const [amount, setAmount] = useState<string>("")
    const [packageInfo, setPackage] = useState<IPackage>()
    const [txHash, setTxHash] = useState<string>("")
    const location = useLocation();
    const { account } = useWeb3React();
    const [locationHistory, setLocationHistory] = useState<Array<string>>([])

    useEffect(() => {
        if (location.pathname.split("/")[1] === RouteNames.UNSTAKING) {
            const pathArray: Array<string> = location.pathname.split("_")
            setStakingStatus(parseFloat(pathArray[pathArray.length - 1]))
        }
        setLocationHistory(prevState => {
            return [...prevState, location.pathname]
        })
    }, [location])

    useEffect(() => {
        setLocationHistory(prevState => {
            
            return [...prevState, location.pathname]
        })
        return setLocationHistory([])
    }, [])

    return (
        <Row>
            <Col md={12} >
                <Row>
                    <h1 className="mt-5 mb-5" style={{color:"white"}}>
                        {Translate("UnStaking.Title")}
                    </h1>
                </Row>
                <HomeButtonRow />
                <Row className="flex-row-reverse" >
                    <Col lg={3} >
                        <StakingStatsContainer2 />
                    </Col>
                    <Col lg={9}>
                        <StakingStatusContainer />
                        <Row className="mt-5 mb-5 justify-content-between">
                            <StakingStatusIcon
                                heading={Translate("UnStaking.StakingStatusIcon1")}
                                imageSource={stakingStatus > StakingStatuses.CHECKPOINT && (CheckpointChecked) || (Checkpoint)}
                                active={stakingStatus > StakingStatuses.CHECKPOINT}
                            />
                            <StakingStatusIcon
                                heading={Translate("UnStaking.StakingStatusIcon2")}
                                imageSource={stakingStatus > StakingStatuses.AMOUNT_TO_STAKE && (AmountToStakeChecked) || (AmountToStake)}
                                active={stakingStatus > StakingStatuses.AMOUNT_TO_STAKE}
                            />
                            <StakingStatusIcon
                                heading={Translate("UnStaking.StakingStatusIcon3")}
                                imageSource={stakingStatus > StakingStatuses.PRE_AUTH && PreAuthChecked || (PreAuth)}
                                active={stakingStatus > StakingStatuses.PRE_AUTH}
                            />
                            <StakingStatusIcon
                                heading={Translate("UnStaking.StakingStatusIcon4")}
                                imageSource={stakingStatus > StakingStatuses.CONFIRM && (ConfirmChecked) || (Confirm)}
                                active={stakingStatus > StakingStatuses.CONFIRM}
                            />
                            <StakingStatusIcon
                                heading={Translate("UnStaking.StakingStatusIcon5")}
                                imageSource={stakingStatus > StakingStatuses.CONFIRMATION && (ConfirmationChecked) || (Confirmation)}
                                active={stakingStatus > StakingStatuses.CONFIRMATION}
                            />
                        </Row>
                        <Row className="UnstakingRoutesMainContainer" >
                            <Col>
                                <Routes>
                                    <Route path={RouteNames.STEP_1} element={<UnStakingWarningContainer
                                        disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                    />} />
                                    <Route path={RouteNames.STEP_2} element={<UnStakingCheckpointsContainer
                                        disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                    />} />
                                    <Route path={RouteNames.STEP_3} element={<UnstakingAmountContainer
                                        amount={amount}
                                        amountHandler={(packageInfo: IPackage, value: string) => { setAmount(value); setPackage(packageInfo); }}
                                        disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                    />} />
                                    <Route path={RouteNames.STEP_4} element={<UnStakingInitialize
                                        setTxHash={setTxHash}
                                        amount={amount}
                                        packageInfo={packageInfo!}
                                        account={account}
                                        history={locationHistory}
                                        disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                    />} />
                                    <Route path={RouteNames.STEP_5} element={<ConfirmationContainer
                                        txHash={txHash}
                                    />} />
                                </Routes>
                            </Col>
                        </Row>
                        <NextButtonComponent modalHandler={false} stakingStatus={stakingStatus} nextDisabled={nextDisabled} />
                    </Col>
                </Row>
            </Col >
        </Row >
    )
}
export default UnStaking;