import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import "./styles.css";
import { Translate } from "../../i18n/localization";


const Cookies = () => {
    return (
        <Container fluid style={{ wordWrap: "break-word"  , textAlign:'justify'}} className="text-align-center text-white">
            <Row  className="mt-2">
                <Col className="text-center">
                    <h1> {Translate("CookiesPolicy.Heading")}</h1>
                    <h5> {Translate("CookiesPolicy.SubHeading")} </h5>
                    <h5>{Translate("CookiesPolicy.LastEdited")}</h5>
                </Col>
            </Row>

            <Row className="mt-5" md="12" xs="12">
                <Col  md={9} className="text-left">
                    <h5>{Translate("CookiesPolicy.CookiesInformationHeading")}</h5>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[0]}</p>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[1]}</p>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[2]}</p>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[3]}</p>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[4]}</p>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[5]}</p>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[6]}</p>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[7]}</p>
                    <p>{Translate("CookiesPolicy.CookiesInformationtext")[8]}</p>
                </Col>
            </Row>

            <Row className="mt-5" md="12" xs="12">
                <Col  className="text-left">
                    <h5>{Translate("CookiesPolicy.ControllingCookiesHeading")}</h5>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[0]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[1]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[2]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[3]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[4]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[5]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[6]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[7]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[8]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[9]}</p>
                    <p>{Translate("CookiesPolicy.ControllingCookiestext")[10]}</p>
                </Col>
            </Row>

            <Row className="mt-5" md="12" xs="12">
                <Col className="text-left">
                    <h5>{Translate("CookiesPolicy.CookiesTypeHeading")}</h5>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[0]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[1]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[2]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[3]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[4]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[5]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[6]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[7]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[8]}</p>
                    <p>{Translate("CookiesPolicy.CookiesTypetext")[9]}</p>
                </Col>
            </Row>



        </Container>
    )
}


export default Cookies;