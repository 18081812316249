import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Col, Container, Progress, Row } from "reactstrap";
import Icon1 from '../../Assets/1.png';
import Icon2 from '../../Assets/2.png';
import Icon3 from '../../Assets/3.png';
import Icon4 from '../../Assets/4.png';
import Astroswap from '../../Assets/Astroswap.png';

import * as RouteNames from "../../Routes/routeNames";
import * as text from "../../Containers/Projects/index";

import './index.css';
import Web3 from "web3";
import { toEth, toUtf8 } from "../../Handlers/converters";
import { SingletonWeb3Instance } from "../../Web3";
import { PROJECT } from "../../Common/TokenConfig";

interface IProps {
    image: any,
    heading: any,
    cap: any,
    access: any,
    description: any,
    swapRate: any

}
const ProjectView = (props: IProps) => {
    const navigate = useNavigate();
    const [tokenName, setTokenName] = useState('')
    const Web3Instance = SingletonWeb3Instance.getInstance();

    const Getname = async () => {

        // const tokenContractPlaceholder = Web3Instance.getTokenContract(props.swaapAddress);
        // var coinName = await Web3Instance.getTokenContract(props.swaapAddress).methods.symbol().call();
        // setTokenName(coinName)
    }


    const stakingContractInstance = Web3Instance.stakingContractInstance;
    useEffect(() => {
        // Getname()
    }, [])

    return (
        <Container className="mt-3 mt-md-0" style={{ backgroundColor: "rgba(62, 51, 71, 0.7)", color: 'white' }}>
            <Row
            // onClick={() => {
            //     navigate({
            //         pathname: `/${RouteNames.DATASCREEN}`,
            //         search: `?${createSearchParams({
            //             ContractAddress: props.indexValue
            //         }).toString()}`
            //     })

            // }}
            >
                <Col>
                    <h5 className="text-end mt-2" style={{ color: "green" }}> Connected </h5>
                    <Row className="mt-3">
                        <Col md="2" className="d-flex">
                            <div>


                            <img src={props.image} style={{ width: "100%", height: '70%' }} />
                            </div>
                        </Col>
                        <Col md="2" />
                        <Col xs="12" md="8">
                            <Row className="text-start text-center-md">
                                <h6 >{props.heading}</h6>
                                <div>
                                    <img src={Icon1} />
                                    <img src={Icon2} />
                                    <img src={Icon3} />
                                    <img src={Icon4} />
                                </div>
                                <br />
                                {props.description}
                            </Row>
                        </Col>
                    </Row>

                    <p>
                        {/* {Web3.utils.toUtf8(props.description)} */}
                    </p>
                    <Row className="mt-3">
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <div >
                                <p>Swap rate</p>
                                <p>{props.swapRate}</p>
                            </div>
                            <div>
                                <p>Cap</p>
                                <p>
                                    {props.cap}
                                </p>
                                {/* <p>{toEth(props.cap)}</p> */}

                            </div>
                            <div>
                                <p>Access</p>
                                <p>
                                    {props.access}
                                </p>
                                {/* <p>{toUtf8(props.accessValue)}</p> */}
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                {/* {tokenName} */}

                                <p>Progress</p>
                            </div>
                            <div>
                                <p>

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Progress striped className="Progressbar" color="danger" value={0} />
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <p>0.00%</p>
                            </div>
                            <div>
                                <p>
                                    0.0000/0
                                </p>
                            </div>

                        </Col>
                    </Row>
                </Col>
            </Row >
        </Container >
    )
}
export default ProjectView;