import React, { useEffect, useState } from "react";
import {
    TabContent, TabPane,
    Nav, NavItem, NavLink,
    Row, Col, Container,
    Progress
} from 'reactstrap';
import HomeButtonRow from "../../Components/HomeButtonRow";
import { Translate } from "../../i18n/localization";
import HomeButton from "../HomeButton";
import { abi as PspAbi } from "../../Common/Contracts/build/Staking.json";
import { AbiItem } from 'web3-utils'

import Icon1 from '../../Assets/1.png'
import Icon2 from '../../Assets/2.png'
import Icon4 from '../../Assets/4.png'
import Icon3 from '../../Assets/3.png'
import Astroswap from '../../Assets/Astroswap.png'
import Mission from '../../Assets/BlueOcean.png'

import './index.css'
import ProjectDetailsTab from "./ProjectDetailsTab";
import SchedulesTab from "./ScheduleTab";
import YourAllocation from "./YourAllocationTab";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Web3 from "web3";
import { Lovelace, PSPAD } from "../../Common/TokenConfig";
import { SingletonWeb3Instance } from "../../Web3";
import { useWeb3React } from "@web3-react/core";
import { toByte32, toByteN, toEth, toUtf8, toUtf8ToHex } from "../../Handlers/converters";
import PoolModal from "../PoolModal";
import Approve from "../ApproveModal/Approve";

const ProjectPage = (props: any) => {
    const history = props.index;
    const [searchParams, setSearchParams] = useSearchParams();
    const [projName, setProjName] = useState('');
    const [projDesc, setProjDesc] = useState('');
    const [fcfsopen, setFcfsOpen] = useState('');
    const [fcfsClosed, setfcfsClosed] = useState('');
    const [fcfsPrepopen, setFcfsPrepOpen] = useState('');
    const [fcfsPrepCloses, setFcfsPrepCloses] = useState('');
    const [symbol, setSymbol] = useState('');
    const [swapRate, setSwapRate] = useState('');
    const [accessType, setAccessType] = useState('');
    const [cap, setCap] = useState('');
    const [seedopenDate, setSeedOpenDate] = useState('');
    const [seedClosedDate, setSeedClosedDate] = useState('');
    const [SceduleDetails, setSceduleDetails] = useState('');
    const [contractAddress, setContractAddress] = useState('');
    const [openModal, setopenModal] = useState<boolean>(false);
    const [openPoolModal, setPoolModal] = useState<boolean>(false);
    const [tokenNames, setTokenNames] = useState('');
    const { account } = useWeb3React();
    const Web3Instance = SingletonWeb3Instance.getInstance();
    const projectContractInstance = Web3Instance.projectContractInstance;
    const fetchData = async () => {
        const AddressToken = searchParams.get('ContractAddress') || "";
        setContractAddress(AddressToken);
        const web3 = new Web3(window.web3.currentProvider);
        const details = await projectContractInstance.methods.getAllProjects().call({});
        setProjName(toUtf8(details[AddressToken][0]));
        setProjDesc(toUtf8(details[AddressToken][2]));
        setSymbol(toUtf8(details[AddressToken][1]));
        setAccessType(toUtf8(details[AddressToken].access));
        setSwapRate(details[AddressToken].swapRate);
        setCap((details[AddressToken].cap));
        setFcfsOpen(details[AddressToken].preSeedRoundOpnenDate);
        setFcfsPrepOpen(details[AddressToken].publicRoundOpenDate);
        setfcfsClosed(details[AddressToken].preSeedRoundClosedDate);
        setFcfsPrepCloses(details[AddressToken].publicRoundClosedDate);
        setSeedOpenDate(details[AddressToken].seedRoundOpenDate);
        setSeedClosedDate(details[AddressToken].seedRoundClosedDated);
        const tokenContractPlaceholder = Web3Instance.getTokenContract(details[AddressToken].swapToken);
        var coinName = await Web3Instance.getTokenContract(details[AddressToken].swapToken).methods.symbol().call();
        setTokenNames(coinName)
    }

    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const toggle = (tab: any) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
    const ApproveFees = () => {
        setPoolModal(true);
    }
    const PoolHandler = () => {
        setopenModal(true);
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Container fluid className="ProjectInfoBoundary">
            <br /> <br />
            <HomeButtonRow />
            <Row >
                <Col md="6" >
                    <div className="d-flex" >
                        <Col md={2} className="ImageContainer d-flex" style={{}}>

                            <div>
                                <img src={Mission} style={{ width: "100px", border: "3px solid gray" }} />
                            </div>
                        </Col>
                        <Col md={3} className="IconsColumn" style={{ marginLeft: '5%' }}>
                            <div>
                                <img src={Icon2} />
                            </div>
                            <div>
                                <img src={Icon1} />
                            </div>
                            <div>
                                <img src={Icon3} />
                            </div>
                            <div>
                                <img src={Icon4} />
                            </div>
                        </Col>
                    </div>

                    <div style={{ marginTop: '2%' }}>
                        <Col className="">
                            <h1>
                                {projName}
                            </h1>
                        </Col>
                    </div>
                    <div  >
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2%' }}>

                            <div >
                                <NavLink className="" to="" style={{ color: 'orange', padding: "0px 23px 0 0px" }}>{Translate("ProjectPage.ProjectStatusText")} </NavLink>
                            </div>
                            <div>
                                <NavLink className="" to="" style={{ color: 'white', padding: "0px 23px 0 0px" }}>{Translate("ProjectPage.NavLinkText")}</NavLink>
                            </div>
                        </div>
                    </div>
                    <Col md="6" className="" style={{ marginTop: '2%', marginBottom: '2%' }}>
                        {projDesc}
                    </Col>
                    {
                        account ?
                            <Row className="" style={{ marginBottom: '4%' }} >

                                <Col lg={4} md={6} >
                                    <HomeButton title="Approve Fees" onClick={() => { PoolHandler() }} />
                                </Col>
                                <Col lg={4} md={6} >
                                    <HomeButton title="Join Pool" onClick={() => { ApproveFees() }} />
                                </Col>
                            </Row>
                            :
                            ""
                    }
                    <PoolModal openModal={openModal} Projname={projName} setOpenModal={setopenModal} />
                    <Approve openModal={openPoolModal} setOpenModal={setPoolModal} Projname={projName} />
                </Col>
                <Col md="6" xs="12" >
                    <Row className="StatusContainer">
                        <Row className="StatusHeaderContainer">
                            <div className="StatusHeader">
                                <p style={{}}> {Translate("ProjectPage.ProjectStatusText")} </p>
                                <br />
                            </div>
                        </Row>
                        <Row className="mt-1">
                            <hr style={{ borderTop: "2px dotted gray" }} />
                        </Row>
                        <Row className="ProgressbarHeaderContainer">
                            <Row>
                                <Col className="ProgressbarHeader">
                                    {Translate("ProjectPage.ProgressText")}
                                </Col>
                            </Row>
                            <Row className="ProgressBarRow" >
                                <Col >
                                    <Progress striped className="Progressbar" color="danger" value={0} />
                                </Col>
                            </Row>
                            <Row >
                                <Col className="PrecentageText">
                                    0 %
                                </Col>
                                <Col className="Completionbar">
                                    0/0
                                </Col>
                            </Row>
                        </Row>
                    </Row>
                </Col>
            </Row>

            <Nav tabs>
                <NavItem>
                    <NavLink
                        className="TabsView"
                        active={currentActiveTab == '1'}
                        onClick={() => { toggle('1'); }}
                    >
                        Project Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className="TabsView"
                        active={currentActiveTab == '2'}
                        onClick={() => { toggle('2'); }}
                    >
                        Schedule
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className="TabsView"
                        active={currentActiveTab == '3'}
                        onClick={() => { toggle('3'); }}
                    >
                        Your Allocation
                    </NavLink>
                </NavItem>
            </Nav>
            <Row>
                <Col>
                    <TabContent className="TabContent" activeTab={currentActiveTab}>
                        <TabPane tabId="1" className="TabContent">
                            <ProjectDetailsTab tokenNames={tokenNames} tokenName={projName} tokenSymbol={symbol} swapRate={swapRate} closed={fcfsPrepCloses} cap={cap} accessType={(accessType)} FFCFSOpens={fcfsopen} Open={fcfsopen} Data={contractAddress} />
                        </TabPane>
                        <TabPane tabId="2" className="TabContent">
                            <SchedulesTab seedClosed={seedClosedDate} seedOpen={seedopenDate} FCFSCLOSED={fcfsClosed} FCFSPREPCLOSED={fcfsPrepCloses} FCFS={fcfsopen} FCFSPREP={fcfsPrepopen} tokenSymbol={symbol} tokenName={projName} Data={SceduleDetails} />
                        </TabPane>
                        <TabPane tabId="3" className="TabContent">
                            <YourAllocation Data={"ss"} />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </Container>
    )
}
export default ProjectPage;