import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import SuccessIcon from "../../Assets/checkpoint.png";
import { BSC_Scan } from "../../Common/URLs";
import { Translate } from "../../i18n/localization";
import "./styles.css";

interface IProps {
    txHash: string
    disableHandler: Function

}

const ConfirmationContainer = (props: IProps) => {
    const viewTx = () => {
        window.open(`${BSC_Scan}/${props.txHash}`, '_blank')?.focus();
       
    }
    return <Row className="StakingAmountContainerStep5">
        <Col md={10} className="offset-md-1 d-flex flex-column align-items-center justify-content-between">
            <div className="stakingSuccessBox">
                <div className="iconClass"><img src={SuccessIcon} /></div>
                <div className="PreAuthHeading">{Translate("Staking.ConfirmationHeading1")}</div>
            </div>
            <div>
                <div className="PreAuthSubHeading">{Translate("Staking.ConfirmationContent1")}</div>
                <div className="PreAuthSubHeading">{Translate("Staking.ConfirmationContent2")}</div>
            </div>
            <div className="StakingConfirmationLink" onClick={() => viewTx()} >
                {Translate("UnStaking.ConfirmationContent3")}
            </div>
        </Col>
    </Row>
}

export default ConfirmationContainer;
