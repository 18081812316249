import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { IPackage } from "../../Common/Models";
import { toByte32, toWei } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { STEP_3 } from "../../Routes/routeNames";
import { SingletonWeb3Instance } from "../../Web3";
import "./styles.css";

interface IProps {
    disableHandler: Function
    Packages: IPackage
    account: string | null | undefined
    setTxHash: Function
    history: Array<string>,
    refferalcode: any,

}

const ConfirmContainer = (props: IProps) => {
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const stakePspWeb3 = async () => {
        const stakingContractPlaceholder = Web3Instance.stakingContractInstance
        const prevLocation = props.history[props.history.length - 1]
        try {
            if (props.refferalcode > 0) {

                const response = await stakingContractPlaceholder
                    .methods
                    .stakeRound(toByte32(props.Packages.name), toByte32(props.Packages.roundName), props.refferalcode)
                    .send({ from: props.account, gasLimit: 9000000 })
                if (prevLocation.split("/")[2] === STEP_3) {

                    props.setTxHash(response.transactionHash)
                    props.disableHandler(false)
                }
            }
            else {
                const response = await stakingContractPlaceholder
                    .methods
                    .stakeRound(toByte32(props.Packages.name), toByte32(props.Packages.roundName), 0)
                    .send({ from: props.account, gasLimit: 9000000 })
                if (prevLocation.split("/")[2] === STEP_3) {

                    props.setTxHash(response.transactionHash)
                    props.disableHandler(false)
                }
            }

        } catch (error) {
            console.log(error);

            props.disableHandler(true)
        }
    }

    useEffect(() => {
        props.disableHandler(true)
        stakePspWeb3()
    }, [props.Packages])

    return <Row className="StakingAmountContainerStep5">
        <Col md={10} className="offset-md-1 d-flex flex-column align-items-center justify-content-between " >
            <div className="PreAuthHeading">{Translate("Staking.ConfirmHeading1")}</div>

            <div className="PreAuthSubHeading">{Translate("Staking.ConfirmContent1")}</div>
            <div className="PreAuthSubHeading">{Translate("Staking.ConfirmContent2")}</div>
            <div className="PreAuthSubHeading">{Translate("Staking.ConfirmContent3")}</div>

        </Col>
    </Row>
}

export default ConfirmContainer;
