import { type } from "os";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Web3 from "web3";
import { IPackage } from "../../Common/Models";
import { STAKING_CONTRACT_ADDRESS } from "../../Common/TokenConfig";
import { toByte32, toEth, toWei } from "../../Handlers/converters";
import { withdrawAmount, withdrawResponse } from "../../Handlers/withdrawCalculator";
import { Translate } from "../../i18n/localization";
import { STEP_3 } from "../../Routes/routeNames";
import { SingletonWeb3Instance } from "../../Web3";

interface IProps {
    account: string | null | undefined
    amount: string
    setTxHash: Function
    disableHandler: Function
    history: Array<string>
    packageInfo: IPackage
}

const UnStakingInitialize = (props: IProps) => {

    const [withdrawData, setWithdrawData] = useState<withdrawResponse>({
        unstakable: false,
        totalAmount: 0,
        reason: "",
        actualAmount: new Array(3).fill(0)
    })

    const Web3Instance = SingletonWeb3Instance.getInstance()
    const stakingContractInstance = Web3Instance.stakingContractInstance
    const tokenContractPlaceholder = Web3Instance.tokenContractInstance
    const getHistoryWeb3 = async () => {
        try {

            const resp = await stakingContractInstance
                .methods
                .unstakeAmount(toWei(props.amount), toByte32(props.packageInfo.roundName), props.packageInfo.stakeIndex)
                .send({ from: props.account, gasLimit: 1000000 });

            props.disableHandler(false)
        } catch (error) {
            console.log(error);
        }
    }
    const unstakePspWeb3 = async () => {
        const prevLocation = props.history[props.history.length - 2]
        if (withdrawData.totalAmount > 0 && prevLocation.split("/")[2] === STEP_3) {
            // const response = await stakingContractPlaceholder.methods.withdraw((toWei(props.amount)).toString(),(withdrawData.totalAmount).toString()).send({ from: props.account })
            // props.setTxHash(response.transactionHash)
            // props.disableHandler(true)
        }
    }
    useEffect(() => {
        getHistoryWeb3()
        props.disableHandler(true)
    }, [])

    useEffect(() => {
        unstakePspWeb3()
    }, [withdrawData])

    return <Row className="UnStakingInitializeContainer">
        <Col md={10} className="offset-md-1 d-flex flex-column align-items-center justify-content-between " >
            <div className="InitializeUnstakeHeading">{Translate("UnStaking.InitializeHeading1")}</div>
            <div className="InitializeUnstakeSubHeading">{Translate("UnStaking.InitializeContent1")}</div>
            <div className="InitializeUnstakeSubHeading">{Translate("UnStaking.InitializeContent2")} {toEth((withdrawData.actualAmount && withdrawData.actualAmount[0])?.toString()) || 0}</div>
            <div className="InitializeUnstakeSubHeading">{Translate("UnStaking.InitializeContent3")} {toEth((withdrawData.actualAmount && withdrawData.actualAmount[1])?.toString()) || 0}</div>
            <div className="InitializeUnstakeSubHeading">{Translate("UnStaking.InitializeContent4")} {toEth((withdrawData.actualAmount && withdrawData.actualAmount[2])?.toString()) || 0}</div>
            <div className="InitializeUnstakeSubHeading">{Translate("UnStaking.InitializeContent5")} {props.amount}</div>
            <div className="InitializeUnstakeSubHeading">{Translate("UnStaking.InitializeContent6")} {toEth((withdrawData.totalAmount).toString()) || 0}</div>
        </Col>
    </Row>
}

export default UnStakingInitialize;
