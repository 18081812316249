import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import {
    Route,
    Routes, useLocation
} from "react-router-dom";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AmountToStake from '../../Assets/amountToStake.png';
import AmountToStakeChecked from '../../Assets/amountToStakeChecked.png';
import Checkpoint from '../../Assets/checkpoint.png';
import CheckpointChecked from '../../Assets/checkpointChecked.png';
import Confirm from '../../Assets/confirm.png';
import Confirmation from '../../Assets/confirmation.png';
import ConfirmationChecked from '../../Assets/confirmationChecked.png';
import ConfirmChecked from '../../Assets/confirmChecked.png';
import PreAuth from '../../Assets/preAuth.png';
import PreAuthChecked from '../../Assets/preAuthChecked.png';
import { IPackage } from "../../Common/Models";
import { PSPAD } from "../../Common/TokenConfig";
import HomeButton from "../../Components/HomeButton";
import HomeButtonRow from "../../Components/HomeButtonRow";
import NextButtonComponent from "../../Components/NextButtonComponent";
import StakingStatsContainer2 from "../../Components/StakingStatsContainer2";
import StakingStatusContainer from "../../Components/StakingStatusContainer";
import StakingStatusIcon from "../../Components/StakingStausIcons";
import * as StakingStatuses from "../../Constants/stakingStatuses";
import { toByteN, toUtf8 } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import * as RouteNames from "../../Routes/routeNames";
import { SingletonWeb3Instance } from "../../Web3";
import StakingAmountContainer from "./StakingAmountContainer";
import StakingCheckpointsContainer from "./StakingCheckpointsContainer";
import ConfirmationContainer from "./StakingConfirmationContainer";
import ConfirmContainer from "./StakingConfirmContainer";
import PreAuthContainer from "./StakingPreAuthContainer";
import StakingStatsContainer from "./StakingStatsContainer";
import "./styles.css";
import { useNavigate } from "react-router";
import Web3 from "web3";
const Staking = () => {
    const location = useLocation();
    const [stakingStatus, setStakingStatus] = useState<number>(1)
    const [nextDisabled, setNextDisabled] = useState<boolean>(false)
    const [packageInfo, setPackage] = useState<IPackage>()
    const [pspBalence, setPspBalences] = useState<string>('')
    const [txHash, setTxHash] = useState<string>("")
    const [RefferalCode, setRefferalCode] = useState<number>(0)
    const { account } = useWeb3React();
    const Web3Instance = SingletonWeb3Instance.getInstance();
    const [locationHistory, setLocationHistory] = useState<Array<string>>([])
    const [modal, setModal] = React.useState(false);
    const [refAddress, setRefAddress] = React.useState('');
    const toggle = () => setModal(!modal);
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.split("/")[1] === RouteNames.STAKING) {
            const pathArray: Array<string> = location.pathname.split("_")
            setStakingStatus(parseFloat(pathArray[pathArray.length - 1]))
        }
        setLocationHistory(prevState => {
            return [...prevState, location.pathname]
        })
    }, [location])
    useEffect(() => {
        setLocationHistory(prevState => {
            return [...prevState, location.pathname]
        })
        return setLocationHistory([])
    }, [])
    const submitHandler = () => {
        toggle()
    }
    const [copySuccess, setCopySuccess] = useState('Copy');
    const [copySuccesss, setCopySuccesss] = useState('Copy');
    const [copyreferal, setreferalcopy] = useState('Copy');
    const stakingContractInstance = Web3Instance.stakingContractInstance;
    const GenerateRefferalCode = async () => {
        const roundName = await stakingContractInstance.methods.getActiveRoundName().call({ from: account });
        const checkCode = await stakingContractInstance.methods.getRefferalCode().call({ from: account });
        const balence = await stakingContractInstance.methods.userBalence(roundName, account).call({ from: account });
        if (balence.length > 0) {

            if (checkCode.length > 1) {
                setRefferalCode(checkCode)
            }
            else {
                await stakingContractInstance.methods.setRefferalCode().send({ from: account });
            }
        }
        else {
            alert('stake balence is not enough')
        }
    }
    const copyToClipBoard = (copyMe: any) => {
        try {
            navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied!');
            setCopySuccesss('Copy');
            setreferalcopy('Copied! ')
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    }
    const copyToClipBoards = (copyMe: any) => {
        try {
            navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copy');
            setCopySuccesss('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    }
    localStorage.setItem('currentItem', '1');
    return (
        <>
            <Row>
                <Col md={12}>
                    <Row >
                        <h1 className="mt-5 mb-5" style={{ color: "white" }}>
                            {Translate("Staking.Title")}
                        </h1>
                    </Row>
                    <HomeButtonRow />
                    <Row className="flex-row-reverse">
                        <Col lg={3} >
                            <Routes>
                                <Route path={RouteNames.STEP_1} element={<StakingStatsContainer />} />
                                <Route path={RouteNames.STEP_2} element={<StakingStatsContainer2 />} />
                            </Routes>
                            <Row>
                                <Col>
                                    <div className="StakingStatsMainContainer1">
                                        <div className="StakingStatsSubContainer1">
                                            <div className="text-white text-center" style={{ borderBottom: "2px dotted rgba(255, 255, 255, 0.3)", fontWeight: "bold" }}>Private Round payments Address</div>
                                            <p className="mt-4 text-white text-center" style={{ fontWeight: "bold" }}>USDT Tether TRC20</p>
                                            <p className="text-center" style={{ color: "white", wordWrap: "break-word" }}>TCmz24rezzucnaBbcL7v7QpWnofRpt6Mfm<span style={{ color: '#4379EC' }} onClick={() => copyToClipBoard("TCmz24rezzucnaBbcL7v7QpWnofRpt6Mfm")}> {copySuccess}</span></p>
                                            <p className="mt-4 text-white text-center" style={{ fontWeight: "bold" }}>BUSD BEP 20</p>
                                            <p className="text-center" style={{ color: "white", wordWrap: "break-word" }}>0xd7BA79719f0aDeA222971EC0A9E6f880D145a846 <span style={{ color: '#4379EC' }} onClick={() => copyToClipBoards("0xd7BA79719f0aDeA222971EC0A9E6f880D145a846")}>{copySuccesss}</span></p>
                                        </div>
                                    </div>
                                    <br />
                                </Col>
                            </Row>
                            <p className="text-center" style={{ color: "white", wordWrap: "break-word" }}>{RefferalCode} </p>
                            <HomeButton
                                title="Generate Referral Code"
                                onClick={() => GenerateRefferalCode()}
                            />
                        </Col>
                        <Col lg={9} >
                            <StakingStatusContainer />
                            <Row className="mt-5 mb-5 justify-content-between">
                                <StakingStatusIcon
                                    heading={Translate("Staking.StakingStatusIcon1")}
                                    imageSource={stakingStatus > StakingStatuses.CHECKPOINT && (CheckpointChecked) || (Checkpoint)}
                                    active={stakingStatus > StakingStatuses.CHECKPOINT}
                                />
                                <StakingStatusIcon
                                    heading={Translate("Staking.StakingStatusIcon2")}
                                    imageSource={stakingStatus > StakingStatuses.AMOUNT_TO_STAKE && (AmountToStakeChecked) || (AmountToStake)}
                                    active={stakingStatus > StakingStatuses.AMOUNT_TO_STAKE}
                                />
                                <StakingStatusIcon
                                    heading={Translate("Staking.StakingStatusIcon3")}
                                    imageSource={stakingStatus > StakingStatuses.PRE_AUTH && PreAuthChecked || (PreAuth)}
                                    active={stakingStatus > StakingStatuses.PRE_AUTH}
                                />
                                <StakingStatusIcon
                                    heading={Translate("Staking.StakingStatusIcon4")}
                                    imageSource={stakingStatus > StakingStatuses.CONFIRM && (ConfirmChecked) || (Confirm)}
                                    active={stakingStatus > StakingStatuses.CONFIRM}
                                />
                                <StakingStatusIcon
                                    heading={Translate("Staking.StakingStatusIcon5")}
                                    imageSource={stakingStatus > StakingStatuses.CONFIRMATION && (ConfirmationChecked) || (Confirmation)}
                                    active={stakingStatus > StakingStatuses.CONFIRMATION}
                                />
                            </Row>
                            <Row className="RoutesMainContainer" >
                                <Col>
                                    <Routes>
                                        <Route path={RouteNames.STEP_1} element={<StakingCheckpointsContainer
                                            disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                            pspBalences={pspBalence}
                                        />} />
                                        <Route path={RouteNames.STEP_2} element={<StakingAmountContainer
                                            pspBalence={pspBalence}
                                            packageName={packageInfo?.name}
                                            amountHandler={(value: IPackage) => {
                                                setPackage(value)
                                            }}
                                            // modalHandler={() => { toggle() }}
                                            disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                        />} />
                                        <Route path={RouteNames.STEP_3} element={<PreAuthContainer
                                            history={locationHistory}
                                            package={packageInfo!}
                                            account={account}
                                            refferalCode={refAddress}
                                            disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                        />} />
                                        <Route path={RouteNames.STEP_4} element={<ConfirmContainer
                                            Packages={packageInfo!}
                                            history={locationHistory}
                                            setTxHash={setTxHash}
                                            account={account}
                                            refferalcode={refAddress}
                                            disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                        />} />
                                        <Route path={RouteNames.STEP_5} element={<ConfirmationContainer
                                            txHash={txHash}
                                            disableHandler={(disable: boolean) => setNextDisabled(disable)}
                                        />} />
                                    </Routes>
                                </Col>
                            </Row>
                            <NextButtonComponent
                                modalHandler={toggle}
                                stakingStatus={stakingStatus} nextDisabled={nextDisabled} />
                        </Col>
                    </Row>
                </Col >
            </Row >
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader
                    toggle={toggle}>Enter Referal Code</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '' }}>
                        <Input
                            className="inputFields"
                            value={refAddress}
                            onChange={(e) => setRefAddress(e.target.value)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { setNextDisabled(false); toggle(); navigate(`./step_${3}`) }}>Skip</Button>
                    <Button disabled={!refAddress} color="primary" onClick={() => { setNextDisabled(false); submitHandler(); navigate(`./step_${3}`) }}>Continue</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default Staking;
