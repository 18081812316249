import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import HomeButton from "../../Components/HomeButton";
import { Translate } from "../../i18n/localization";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { GOOGLE_IDO_FORM, GOOGLE_WHITELIST_FORM, PANCAKESWAP, Token_Launch_Auction_Whitelist, UNISWAP } from "../../Common/URLs";
import * as RouteNames from "../../Routes/routeNames";

const Home = () => {

    const navigate = useNavigate()

    const openTab = (link: string) => {
        window.open(link, '_blank')?.focus();
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className="PSPadTitle">{Translate("Home.MainHeading")[0]}</div>
                </Col>
            </Row>
            <Row >
                <Col md={9}>

                    <Row>
                        <Col className="HomeMainHeading" >
                            {Translate("Home.MainHeading")[1]}
                            <b>{Translate("Home.MainHeading")[2]}</b>
                            <b>{Translate("Home.MainHeading")[3]}</b>
                            <b>{Translate("Home.MainHeading")[4]}</b>
                            <b>{Translate("Home.MainHeading")[5]}</b>
                            <b>{Translate("Home.MainHeading")[6]}</b>
                            {Translate("Home.MainHeading")[7]}
                            <b>{Translate("Home.MainHeading")[8]}</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="HomeSubHeading">
                            {Translate("Home.SubHeading")}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="HomeMainContainer2">
                <Col md={9} className="d-md-flex flex-direction-md-row">
                    <HomeButton title={Translate("Button.UniSwapButton")} style={{ marginRight: "1vh" }} onClick={() => { openTab(UNISWAP) }} />
                    <HomeButton title={Translate("Button.PancakeButton")} style={{ marginRight: "1vh" }} onClick={() => { openTab(PANCAKESWAP) }} />
                    <HomeButton title={Translate("Button.IDOButton")} style={{ marginRight: "1vh" }} onClick={() => { openTab(GOOGLE_IDO_FORM) }} />
                    <HomeButton title={Translate("Button.WhiteListForm")} style={{ marginRight: "1vh" }} onClick={() => { openTab(GOOGLE_WHITELIST_FORM) }} />
                    <HomeButton title={Translate("Button.StakingButton")} style={{ marginRight: "1vh" }} onClick={() => { navigate(`/${RouteNames.STAKING}/${RouteNames.STEP_1}`) }} />
                    <HomeButton title={Translate("Button.TokenLaunch")} style={{ marginRight: "1vh" }} onClick={() => { openTab(Token_Launch_Auction_Whitelist) }} />
                </Col>
            </Row>
        </Container>
    )

}

export default Home;

