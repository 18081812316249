import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { PSPAD, STAKING_CONTRACT_ADDRESS } from "../../Common/TokenConfig";
import { toEth, toWei } from "../../Handlers/converters";
import { SingletonWeb3Instance } from "../../Web3";
import HomeButton from "../HomeButton";


interface IProps {
    openModal: boolean;
    setOpenModal: Function;
    Projname: any

}

const PoolModal = (props: IProps) => {
    const [amount, setamount] = useState('');
    const Web3Instance = SingletonWeb3Instance.getInstance();

    const { account } = useWeb3React();

    const stakingContractInstance = Web3Instance.stakingContractInstance;
    const tokenContractPlaceholder = Web3Instance.getTokenContract(PSPAD);
    const ApproveButton = async () => {
        // await tokenContractPlaceholder.methods.approve(STAKING_CONTRACT_ADDRESS, (toWei(amount))).send({ from: account });
        // props.setOpenModal(false)
        try {

            var coinBalance = await Web3Instance.getTokenContract(PSPAD).methods.balanceOf(account).call({ from: account });
            if (toEth(coinBalance) < amount) {
                alert('not enough Balance');
                props.setOpenModal(false)

            }
            else {
                await Web3Instance.getTokenContract(PSPAD).methods.approve(STAKING_CONTRACT_ADDRESS, toWei(amount)).send({ from: account });

                // await stakingContractInstance.methods.claimedAllocation((toWei(amount))).send({ from: account, gasLimit: 200000 });
                props.setOpenModal(false)
            }
        }
        catch (err) {
            alert(err)
        }
    }
    return (
        <>
            <Modal
                isOpen={props.openModal}
                centered={true}
                backdrop={true}
                size="md"
            >
                <ModalHeader className="withdrawModalContainer">
                    <h4> Join {props.Projname} Pool</h4>
                </ModalHeader>
                <ModalBody className="withdrawModalContainer">
                    <Row style={{ display: 'flex', alignSelf: 'center' }}>
                        <Col md="4">
                            <p>
                                BUSD amount:
                            </p>
                        </Col>
                        <Col md="6">
                            <Input
                                placeholder="Enter Amount"
                                value={amount}
                                onChange={(e) => setamount(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row style={{
                        display: 'flex', justifyContent: 'center',
                        //  marginLeft:'11%' 
                    }}
                    >
                        <Col md="6">
                            <HomeButton
                                title="Approve"
                                onClick={() => { ApproveButton() }}
                            />
                        </Col>
                        <Col md="6">
                            <HomeButton
                                title="Closed"
                                onClick={() => { props.setOpenModal(false) }}
                            />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}
export default PoolModal;