import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import AmountInput from "../../Components/AmountInput";
import { toEth } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";
import "./styles.css";
import HomeButton from "../../Components/HomeButton";
import Web3 from "web3";
import { toByte32, toUtf8 } from "../../Handlers/converters";
import { IPackage } from "../../Common/Models";
import { PSPAD } from "../../Common/TokenConfig";

interface IProps {
    // amount: string
    amountHandler: Function
    disableHandler: Function
    packageName: string | undefined
    pspBalence: string | undefined
}

const StakingAmountContainer = (props: IProps) => {
    const [pspBalance, setPspBalance] = useState<string>('')
    const [chosenPackageName, setChoosenPackageName] = useState<String>()
    const [packages, setPackages] = useState<IPackage[]>([]);
    const { account } = useWeb3React();
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const stakingContractInstance = Web3Instance.stakingContractInstance
    const getRoundAsync = async () => {
        const roundName = await stakingContractInstance.methods.getActiveRoundName().call({ from: account });
        // console.log(roundName);

        const packagesNames = await stakingContractInstance.methods.getRoundPackageNames(roundName).call({ from: account });
        const packages = await Promise.all<IPackage>(packagesNames.map(async (key: string, index: number) => {
            const pkg = await stakingContractInstance.methods.getRoundPackage(roundName, key).call({ from: account });
            return { name: toUtf8(pkg.name), stakingAmount: Web3Instance.web3!.utils.fromWei(pkg.stakingAmount, 'ether'), poolWeight: pkg.poolWeight, maxUsers: pkg.maxUsers, roundName: toUtf8(roundName) };
        }));
        setPackages(packages);
    }
    if (chosenPackageName === undefined || chosenPackageName === '') {
        props.disableHandler(true)
    }
    else {
        props.disableHandler(false)
    }
    useEffect(() => {

        getRoundAsync();
    }, [account])
    return <Row className="StakingAmountContainer">
        <Col md={12} className="offset-md-1 d-flex flex-column align-items-center justify-content-between" >
            <h3 className="StakingAmountHeading">{`Please select the package from the following`}</h3>
            {
                packages!.map((key, value) => {
                    return (
                        <Col md={3} key={key.name} style={{ borderWidth: '2vh', borderColor: 'black' }} >
                            <HomeButton title={key.name} style={{ width: "100%", background: props.packageName == key.name ? '#EE7E40' : 'black' }} onClick={() => {
                                setChoosenPackageName(key.name);
                                props.amountHandler(key)
                                props.disableHandler(false)
                            }} />
                            {

                            }
                            <div>
                                {
                                    <p style={{ color: 'white', textAlign: 'center' }}> Amount: {key.stakingAmount} Pool Weight : {key.poolWeight}</p>
                                }

                            </div>
                        </Col>
                    )
                })

            }
        </Col>
    </Row>
}

export default StakingAmountContainer;
