import React from "react";
import {
    Modal, ModalBody, ModalFooter, Button
} from 'reactstrap';
import HomeButton from "../../Components/HomeButton";
import MetaMask from "../../Assets/metamask.png";
import TrustWallet from "../../Assets/trustWallet.jpg";
import { Translate } from "../../i18n/localization";
import "./styles.css";
import { METAMASK, TRUST_WALLET } from "../../Common/WalletURLs";

interface IProps {
    openModal: boolean;
    setOpenModal: Function
}

const WalletsModal = (props: IProps) => {
    return (
        <Modal
            isOpen={props.openModal}
            centered={true}
            backdrop={true}
            size="sm"
        >
            <ModalBody className="modalContainer">
                <Button style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} onClick={() => { window.open(METAMASK, "_blank") }} >
                    <img alt="MetaMask" src={MetaMask} style={{ width: "15vw", height: "auto", marginRight: "2vw" }} />
                    <div>{Translate("ModalTexts.MetaMask")}</div>
                </Button>
                <Button style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5vw", width: "100%" }} onClick={() => { window.open(TRUST_WALLET, "_blank") }} >
                    <img alt="MetaMask" src={TrustWallet} style={{ width: "15vw", height: "auto", marginRight: "2vw" }} />
                    <div>{Translate("ModalTexts.TrustWallet")}</div>
                </Button>
            </ModalBody>
            <ModalFooter className="modalContainer">
                <HomeButton title={Translate("ModalTexts.Close")} onClick={() => props.setOpenModal(false)} />
            </ModalFooter>
        </Modal>

    )
}


export default WalletsModal;