import { useWeb3React } from "@web3-react/core";
import { translate } from "i18n-js";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { PSPAD, ZUSD } from "../../Common/TokenConfig";
import StakingCheckpoints from "../../Components/StakingCheckpoints";
import { toEth, toWei } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";
import "./styles.css";

const Big = require('big.js');

interface IProps {
    disableHandler: Function,
    pspBalences: string
}

const StakingCheckpointsContainer = (props: IProps) => {
    const { active, account } = useWeb3React();
    const [pspBalance, setPspBalance] = useState<string>('0')
    const [coinName, setCoinName] = useState('');
    const [pspSymbol, setpspSymbol] = useState('');
    const [pspbal, setPspBal] = useState('');
    const [bnbBalance, setBNBBalance] = useState<string>('0')
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const minPspBalance = 0.000001
    const minBNBBalance = 0.000001
    const walletConnectionCheck: boolean = active;
    const bnbBalanceCheck: boolean = Big(bnbBalance).gt(minBNBBalance);
    const pspBalanceCheck: boolean = Big(pspBalance).gt(minPspBalance);
    const stakingCheck: boolean = Big(pspBalance).gt(minPspBalance);

    const getBalanceFromWeb3 = async () => {
        if (account !== (null || undefined)) {

            var coinBalance = await Web3Instance.getTokenContract(ZUSD).methods.balanceOf(account).call();
            var psptokens = await Web3Instance.getTokenContract(PSPAD).methods.balanceOf(account).call();
            var coinGasBalance = await Web3Instance.web3!.eth.getBalance(account!);
            var coinName = await Web3Instance.getTokenContract(ZUSD).methods.symbol().call();
            var pspBalance = await Web3Instance.getTokenContract(PSPAD).methods.symbol().call();
            setCoinName(coinName)
            setPspBal(toEth(psptokens))
            setpspSymbol(pspBalance)
            setPspBalance(toEth(coinBalance));
            setBNBBalance(toEth(coinGasBalance));
            if (coinBalance > 0) {
                props.disableHandler(false)
            }
            else {
                props.disableHandler(true)

            }
            return
        }
        setPspBalance('0')
        setPspBal('0')
        setBNBBalance('0')
        props.disableHandler(true)
    }

    useEffect(() => {
        getBalanceFromWeb3()
    }, [active, account])

    return <Row className="CheckpointsContainer">
        <Col md={12} >
            <h3 className="CheckpointsHeadingMain">{Translate("Staking.CheckpointsHeading")}</h3>
            <h4 className="CheckpointsHeading">{Translate("Staking.CheckpointsContent")}</h4>
        </Col>
        <Col xl={3} md={6} xs={12} >
            <StakingCheckpoints mainHeading={Translate("Staking.StakingCheckpointHeading1")} subHeading={Translate("Staking.StakingCheckpointSubHeading1")} checked={walletConnectionCheck} />
        </Col>
        <Col xl={3} md={6} xs={12} >
            <StakingCheckpoints mainHeading={`Wallet Balances`} subHeading={`${coinName} Balance : ${pspBalance}`} pspadHeading={`${pspSymbol}` + " Balance: " } pspad={pspbal} checked={pspBalanceCheck} />
        </Col>
        <Col xl={3} md={6} xs={12} >
            <StakingCheckpoints mainHeading={Translate("Staking.StakingCheckpointHeading3")} subHeading={`${Translate("Staking.StakingCheckpointSubHeading3")} ${bnbBalance}`} checked={bnbBalanceCheck} />
        </Col>
        <Col xl={3} md={6} xs={12} >
            <StakingCheckpoints mainHeading={Translate("Staking.StakingCheckpointHeading4")} subHeading={Translate("Staking.StakingCheckpointSubHeading4")} checked={stakingCheck} />
        </Col>
    </Row>
}

export default StakingCheckpointsContainer;
