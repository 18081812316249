import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import "./styles.css";

class HowItWorks extends Component {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <Container fluid className="AboutUsContainer" >
                <div style={{ backgroundColor: '' }} >

                </div>
                <div style={{

                }}>
                    <Row>
                        <Col md="8">
                            <h3 style={{ fontWeight: 'bold' }}> PSPad is backed by Polkadotlabs (PDL). </h3>
                            <p style={{}}> PDL is a  remarkable Blockchain Research lab and delicate marketing platform
                                for start-ups.</p>
                            <p style={{ marginTop: '-1.5%' }}>
                                PDL is comprised of enthusiastic developers with profound knowledge and expertise in blockchain tech. This Platform provide opportunities to the developers of futuristic projects and web3.0 builders to pursue their programmes over it for best outcome. PDL team has a successful track record of launching projects and promoting those projects on a large scale into the tens of millions in market cap, daily volume and make it trending on various social media platforms. On top of that, PDL have a reputation for locking liquidity and ensuring safe launches for their projects.
                                PDL incorporates high-tech developers (Full-stack, smart contracts, DApps, DEX, DeFi, Metaverse & AAA P2E Game developers) along with Financial Planning Analysts, Tech savvy designers, Successful entrepreneurs in E-commerce industry, along with Digital marketing experts. You just name it!
                                The team possess every resource, knowledge, expertise and solid experience to deliver.
                                ‘Polkadotlabs is a beginning of a new dawn in blockchain space’
                            </p>
                        </Col>
                    </Row>

                </div>

                {/* </Row> */}

            </Container>
        )
    }
}

export default HowItWorks;

