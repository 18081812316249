import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import "./styles.css";
// import { AiOutlineMedium, AiOutlineTwitter, AiOutlineReddit } from "react-icons/ai";
// import { FaFacebookF } from "react-icons/fa";


class HowItWorks extends Component {
    render() {
        return (
            <Container Fuid className="SocialMediaContainer text-white" style={{ wordWrap: "break-word" }}>



                <Row className="mt-2">
                    <Col className="text-center">
                        <h1> PolkaStarterPad Socials</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Col xs="12" md="12" >

                        <Row className="justify-content-center">
                            <Col xs="11" className="justify-content-center">
                                <div >
                                    <Row >
                                        
                                        <Col>
                                            <p className="FieldHeaderStyle">
                                            </p>
                                            <p className="FieldHeaderStyle">
                                                {/* <AiOutlineTwitter className="SocialIcon" />&nbsp; Twitter */}
                                                Twitter
                                            </p>
                                            <a style={{ color: "white" }} href="https://twitter.com/PadPolkastarter">
                                                https://twitter.com/PadPolkastarter
                                            </a>

                                        </Col>
                                        <Col>
                                            <p className="FieldHeaderStyle">

                                            </p>
                                            <p className="FieldHeaderStyle">

                                                {/* < AiOutlineMedium className="SocialIcon" />&nbsp;  */}
                                                Medium
                                            </p>
                                            <a style={{ color: "white" }} href="https://medium.com/@polkastarterpad.io">
                                                https://medium.com/@polkastarterpad.io
                                            </a>

                                        </Col>
                                    </Row>

                                    <Row className="mt-0 mt-md-5">
                                        
                                        <Col>
                                            <p className="FieldHeaderStyle">

                                            </p>
                                            <p className="FieldHeaderStyle">
                                                {/* <FaFacebookF className="SocialIcon"/>&nbsp; Facebook */}
                                                Telegram
                                            </p>
                                            <a style={{ color: "white" }} href="https://t.me/+6f7H4ORTl4k4ZGNk">
                                                https://t.me/+6f7H4ORTl4k4ZGNk
                                            </a>

                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default HowItWorks;

