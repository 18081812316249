import React from "react";
import { Col } from "reactstrap";
import "./styles.css";

interface IProps {
    heading?: string
    children?: any
    content?: any
    paddingRight?: boolean
    paddingLeft?: boolean
}

const StakingStatus = (props: IProps) => {
    return <Col lg={4} className={`StakingStatusColContainer ${props.paddingRight && "SSCColPaddingRight"} ${props.paddingLeft && "SSCColPaddingLeft"} `}>
        <div className="StakingStatusMainContainer">
            <div className="StakingStatusHeading">{props.heading}</div>
            <div className="StakingStatusContent">{props.content}</div>
        </div>
        <br />
    </Col>
}

export default StakingStatus;
