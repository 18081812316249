import { useWeb3React } from "@web3-react/core";
import React from "react";
import { Container, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { injected } from "../../Common/InjectedChainIds";
import HomeButton from "../../Components/HomeButton";
import "./styles.css";


interface IProps {
    openModal: boolean;
    setOpenModal: Function
    disconnectHandler: Function
}

const DisconnectWallet = (props: IProps) => { 
  const { active: networkActive, error: networkError, activate: activateNetwork , deactivate } = useWeb3React()

    const DisConnectHanlder = () => {
        // if ( injected) {
        //     // activateNetwork(injected )
        //     // deactivate()
        //     networkActive
        //   }
        localStorage.setItem('key' , 'false')
        props.disconnectHandler()
        props.setOpenModal(false)
    }
    return (
        <Container fluid>
            <Modal
                isOpen={props.openModal}
                centered={true}
                backdrop={true}
                size="sm">
                <ModalHeader className="modalContainer">
                    Disconnect ?
                </ModalHeader>
                <ModalFooter className="modalContainer">
                    <HomeButton title={"Disconnect"} onClick={() => {
                      DisConnectHanlder()
                    }} />
                    <HomeButton title={"Close"} onClick={() => props.setOpenModal(false)} />

                </ModalFooter>
            </Modal>
        </Container>
    )
}


export default DisconnectWallet;