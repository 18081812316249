import { useWeb3React } from "@web3-react/core";
import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";
import "./styles.css";

interface IProps {
    disableHandler: Function
}

const UnStakingWarningContainer = (props: IProps) => {
    const { account } = useWeb3React();
    const Web3Instance = SingletonWeb3Instance.getInstance()

    const stakingContractInstance = Web3Instance.stakingContractInstance

    const details = async () => {
        const resp= await stakingContractInstance.methods
        
    }
    useEffect(() => {
        if (account) {
            props.disableHandler(false)
            return
        }
        details()

        props.disableHandler(true)
    }, [account])
    return <Row className="UnStakingWarningContainer">
        <Col md={10} className="offset-md-1 d-flex flex-column align-items-center justify-content-between " >
            <h5 className="UnStakingAmountSubHeading">{Translate("UnStaking.WarningHeading")}</h5>
            <h5 className="UnStakingAmountSubHeading">{Translate("UnStaking.WithdrawContent3")} : {Translate("UnStaking.WithdrawPercentage3")}</h5>
            <h5 className="UnStakingAmountSubHeading">{Translate("UnStaking.WithdrawContent2")} : {Translate("UnStaking.WithdrawPercentage2")}</h5>
            <h5 className="UnStakingAmountSubHeading">{Translate("UnStaking.WithdrawContent1")} : {Translate("UnStaking.WithdrawPercentage1")}</h5>
        </Col>
    </Row>
}

export default UnStakingWarningContainer;
