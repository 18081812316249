import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Web3 from "web3";
import { IPackage } from "../../Common/Models";
import AmountInput from "../../Components/AmountInput";
import HomeButton from "../../Components/HomeButton";
import { toByte32, toEth, toUtf8, Utils } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";
import "./styles.css";

const Big = require('big.js')

interface IProps {
    amount: string;
    amountHandler: (packageInfo: IPackage, amount: string) => any;
    disableHandler: Function;
}

interface IUnstakeable {
    unstakable: string;
    total: string;
    isRewardPeriod: boolean;
}

const UnStakingAmountContainer = (props: IProps) => {
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const [myPackages, setMyPackages] = useState<IPackage[]>([])
    const [selectedPackage, setPackage] = useState<IPackage>()
    const [selectedPackagePaymentInfo, setPackagePaymentInfo] = useState<IUnstakeable>({ isRewardPeriod: false, total: '0', unstakable: '0' })

    const { account } = useWeb3React();
    const stakingContractInstance = Web3Instance.stakingContractInstance

    function removeNull(array: IPackage[]) {
        return array.filter(x => x !== null)
    };

    const setAmount = ((amount: string) => {
        props.amountHandler(selectedPackage!, amount);
    });

    const getStakedAsync = async () => {
        const roundNames: string[] = await stakingContractInstance.methods.getRoundNames().call({ from: account });
        const packages = await Promise.all(roundNames.map(async (key: string, index: number) => {
            const pkgs = await stakingContractInstance.methods.getStaked(key).call({ from: account });
            return pkgs.map((pkg: any, index: number) => {
                return { name: toUtf8(pkg.packageName), stakingAmount: toEth(pkg.amount), timeStamp: pkg.timestamp, roundName: toUtf8(key), remainingAmount: toEth(pkg.remainingAmount), stakeIndex: pkg.stakeIndex };
            })
        }));
        const flattended = packages.flat(1);
        setMyPackages(removeNull(flattended));

    }

    const setPackageAmount = async (packageInfo: IPackage) => {
        const pkgs = await stakingContractInstance.methods.calculateUnstakableAmount(toByte32(packageInfo.roundName), packageInfo.stakeIndex).call({ from: account });
        setPackagePaymentInfo({ isRewardPeriod: pkgs.isRewardPeriod, total: toEth(pkgs.total), unstakable: toEth(pkgs.unstakable) });
    };

    const setNextStep = (amount: string) => {
        if (!amount || '0') {
            if (amount == '')
                props.disableHandler(true)
            return
        }
        let isNotAmount = !amount.match("^[0-9]*\\.?[0-9]*$") || !(/(\.\d{0,20})$/).test(amount);

        let bigAmount = Big(amount);

        let allowedAmount = Big(selectedPackagePaymentInfo.unstakable);

        if (bigAmount.gt(allowedAmount)
            || bigAmount.eq(0) || isNotAmount
        ) {
            props.disableHandler(true)
            return
        }
        props.disableHandler(false)
    };

    useEffect(() => {
        getStakedAsync();
    }, [account]);

    useEffect(() => {
        if (selectedPackage == null) return;
        setPackageAmount(selectedPackage!);
        props.amountHandler(selectedPackage, selectedPackagePaymentInfo.unstakable);
    }, [selectedPackage]);

    useEffect(() => {
        setNextStep(props.amount);
    }, [props.amount]);

    return <Row className="StakingAmountContainer">
        <Col md={8} className="d-flex flex-column align-items-center justify-content-between col-md-12" >
            <h3 className="UnStakingAmountHeading">{Translate("UnStaking.EnterAmountHeading")}</h3>
            {/* <Row style={{ display: 'flex', marginRight: '0%' }}> */}
            <br />
            {
                myPackages.map((key: IPackage, index: number) => {
                    return (

                        <Row >
                            <Col key={`${key.roundName}-${key.name}`} md="12" >
                                <HomeButton title="" onClick={() => { setPackage(key); props.disableHandler(false) }} style={{ margin: '2%', width: '100%', innerHeight: '2vh' }} >
                                    {/* <HomeButton title="" style={{  margin: '2%', width: '100%'  , innerHeight:'2vh' }} onClick={()=> setPackage(key)}> */}
                                    <Col >
                                        Round: {key.roundName}
                                    </Col>
                                    <Col>
                                        Package: {key.name}
                                    </Col>
                                    <Col >
                                        Amount: {key.remainingAmount}
                                    </Col>

                                </HomeButton>
                            </Col>
                        </Row>

                    )
                })
            }

            <AmountInput
                placeholder={Translate("UnStaking.EnterAmountInputPlaceholder")}
                groupText={Translate("UnStaking.EnterAmountInputGroupText")}
                value={selectedPackagePaymentInfo?.unstakable!}
                onChangeHandler={(text: string) => setAmount(text.replace("^[0-9]*\\.?[0-9]*$", ""))}
                onClick={() => {
                    setAmount(selectedPackagePaymentInfo?.unstakable)
                }} />
            <div style={{ color: "white" }} >{Translate("UnStaking.EnterAmountBalance")} : <span style={{ color: "#c361ba" }} >{selectedPackagePaymentInfo?.total}</span> </div>
        </Col>
    </Row>
}

export default UnStakingAmountContainer;

