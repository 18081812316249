import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import HomeButton from "../../Components/HomeButton";
import { toEth, toWei, Utils } from "../../Handlers/converters";
import { uniqueArray } from "../../Handlers/utilities";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";
import WithdrawModal from "../WithdrawModal";
import "./styles.css";
const StakingStatsContainer2 = () => {
    interface ICoinBalance {
        coinAddress: string;
        coinBalance: string;
        coinName: string;
    }
    const Web3Instance = SingletonWeb3Instance.getInstance()
    const { active, account } = useWeb3React();
    const [reward, setReward] = useState<number>(0)
    const [stakedAmount, setStaked] = useState<ICoinBalance[]>([])
    const [openWithDrawModal, setWithdrawModal] = useState<boolean>(false)
    const stakingContractPlaceholder = Web3Instance.stakingContractInstance
    const getBalanceFromWeb3 = async () => {
        const stakingContractPlaceholder = Web3Instance.stakingContractInstance
        if (account !== (null || undefined)) {
            const stakedAmounts = await stakingContractPlaceholder.methods.getStakedAll().call({ from: account })
            const stakedAmountsWithName = await Promise.all(stakedAmounts.map(async (coin: ICoinBalance) => {
                const coinName = await Web3Instance.getTokenContract(coin.coinAddress).methods.symbol().call({ from: account });
                return { coinName: coinName, coinAddress: coin.coinAddress, coinBalance: coin.coinBalance };
            }));
            setStaked(uniqueArray(stakedAmountsWithName));
            return;
        }
    };
    useEffect(() => {
        getBalanceFromWeb3();
    }, [active, account])

    return <Col className="StakingStatsContainer2New"  >
        <div className="SSC2SubContainerNew" >
            <div className="SSC2SubContainer2New" >
                <div className="SSC2StakedContainerNew" >
                    <div className="SSC2StakedHeadingNew" >
                        {Translate("Staking.Staked")}
                    </div>
                    <div className="SSC2StakedAmountNew" >
                        <Row>{
                            stakedAmount.map((coin: ICoinBalance, index: number) => {
                                return (
                                    <Col >

                                        <p><span style={{ color: "white" }}><b>{coin.coinName}</b></span><br /><span style={{ color: "#c361ba" }}>{toEth(coin.coinBalance)}</span></p>
                                    </Col>
                                );
                            })
                        }
                        </Row>
                    </div>
                </div>
                <div className="SSC2RewardContainerNew" >
                    <div className="SSC2RewardHeadingNew" >
                        {Translate("Staking.Reward")}
                    </div>
                    <div className="SSC2RewardAmountNew">
                        {reward}
                    </div>
                </div>
            </div>
            <div className="SSC2ButtonsContainerNew" >
                <Col md={10} sm={7} xs={11}>
                    <HomeButton title={Translate("Button.Withdraw")} disabled onClick={() => { setWithdrawModal(true) }} />
                </Col>
                <Col className="mt-2" md={10} sm={7} xs={11} >
                    <HomeButton title={Translate("Button.AddToMetaMask")} disabled onClick={async () => {
                        await stakingContractPlaceholder.methods.withdrawReward().send({ from: account })
                    }} />
                </Col>
            </div>
            <WithdrawModal openModal={openWithDrawModal} setOpenModal={setWithdrawModal} rewardAmount={reward.toString()} />
        </div>
    </Col>
}

export default StakingStatsContainer2;
