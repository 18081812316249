import React, { Component, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import HomeButtonRow from "../../Components/HomeButtonRow";
import Project from "../../Components/ProjectsView";
import "./styles.css";
import { MetaVPad, PulsePad, Lovelace } from "../../Common/TokenConfig";
import Mission1 from '../../Assets/Keplerr.png'
import Mission2 from '../../Assets/BlueOcean.png'
import Mission3 from '../../Assets/mission_img3.jpg'
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { SingletonWeb3Instance } from "../../Web3";
import HomeButton from "../../Components/HomeButton";


const Projects = () => {

    const { account } = useWeb3React();
    const [allRounds, setAllRounds] = useState([]);
    const [openRound, setOpenRound] = useState<number[]>([]);
    const [upcomingRound, setComingRound] = useState<number[]>([]);
    const [closedRound, setClosedRound] = useState<number[]>([]);
    const Web3Instance = SingletonWeb3Instance.getInstance();
    const stakingContractInstance = Web3Instance.stakingContractInstance;
    const projectContractInstance = Web3Instance.projectContractInstance;
    const [projName, setProjName] = useState('')
    const [projDesc, setProjDesc] = useState('')
    const [swapRate, setSwapRate] = useState('')
    const [accessProp, setAccess] = useState('')


    const FetchData = async () => {

        let rounds = await projectContractInstance.methods.getAllProjects().call({});
        setAllRounds(rounds);
        let time = 500
        const currentDate = + new Date()
        rounds.map((round: any) => {
            if (currentDate > round.publicRoundClosedDate) {
                closedRound.push(round)
                let close: any = [...closedRound]
                setClosedRound(close)
            }
            else if (currentDate < round.publicRoundOpenDate) {
                upcomingRound.push(round)
                let coming: any = [...upcomingRound]
                setComingRound(coming)
            }
            else {
                openRound.push(round)
                let open: any = [...openRound]
                setOpenRound(open)
            }

        })


    }
    useEffect(() => {
        FetchData()
    }, []);
    return (
        <Container fluid>

            <Row style={{marginTop:'3%' , marginBottom:'0.4%'}}>
                <Col md="6">
                    <Project image={Mission2} cap="112" heading="BluOcean" access="Private" description="Tamps as a Service platform allowing creators to easily develop digital products with gamified rewards." swapRate="1500.630" />
                </Col>
                <Col md="6">
                    <Project image={Mission1} cap="120" heading="Kepler2.0" access="Public" description="KEPLER was born for Metaverse. The Immigration Plan is the first step toward Metaverse for KEPLER" swapRate="6554.630" />
                </Col>
            </Row>
        </Container>


    )
}


export default Projects;

