import React, { useEffect, useState } from "react";
import { Component } from 'react';
import { Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { SingletonWeb3Instance } from "../../Web3";
import { useWeb3React } from "@web3-react/core";
import { toByte32, toEth, toUtf8, toWei } from "../../Handlers/converters";
import Loader from '../../Components/Loader/index';
import Web3 from "web3";
import { IListing, IPackage } from "../../Common/Models";

interface IProps {
    RowIndex: number;
    Address: string;
    isLoading: boolean;
    Round: string;
    Listing: IListing;
    Packages: string[];
    onStaked: { (): void }
}

const PreseedStakeRow = (props: IProps) => {
    const Web3Instance = SingletonWeb3Instance.getInstance();
    const stakingContractInstance = Web3Instance.stakingContractInstance
    const { active, account } = useWeb3React();
    const [selectedPackage, setSelectedPackage] = useState('')
    const [refresh, setRefresh] = useState(false);
    const [color, setColor] = useState('blue');
    const [staked, setStaked] = useState<IPackage[]>([]);
    const [modal, setModal] = React.useState(false);
    const [refAddress, setRefAddress] = useState<string>();
    const toggle = () => setModal(!modal);

    useEffect(() => {
        getRoundAsync()

    }, [props.Round])

    const getRoundAsync = async () => {
        staked.splice(0, staked.length);//Staked(address indexed staker, address indexed token, bytes32 indexed round, bytes32 package, uint256 amount, bool isAdmin)//, null, toByte32(props.Round)
        const abi = Web3Instance.web3!.eth.abi;
        SingletonWeb3Instance.subscribeTransaction(stakingContractInstance._address,
            [Web3.utils.sha3("Staked(address,address,bytes32,bytes32,uint256,bool)"),
            abi.encodeParameter('address', props.Address), null, abi.encodeParameter('bytes32', toByte32(props.Round))], (data) => {
                const addr = abi.decodeParameter('address', data.topics[1]);
                const token = abi.decodeParameter('address', data.topics[2]);
                const round = abi.decodeParameter('bytes32', data.topics[3]);
                const eventData = abi.decodeParameters(['bytes32', 'uint256', 'bool'], data.data);
                staked.push({ name: toUtf8(eventData[0]), stakingAmount: eventData[1], maxUsers: 0, poolWeight: 0, roundName: toUtf8(round), stakeIndex: 0, remainingAmount: 0 });
                setStaked(staked);
            });

        setSelectedPackage(props.Packages[0]);
    }

    const EventHandler = async () => {
        toggle()
        const isRefferalValid = await stakingContractInstance.methods.checkAddress(refAddress).call({ from: account })
        if (isRefferalValid) {
            await stakingContractInstance.methods.stakeAdmin(props.Address,
                toByte32(selectedPackage),
                toByte32(props.Round),
                refAddress
            )
                .send({ from: account, gasLimit: 2000000 });
        }
        else {
            alert("Wrong Refferal Code , Please try again")
        }
        // await stakingContractInstance.methods.stakeAdmin(props.Address,
        //     toByte32(selectedPackage),
        //     toByte32(props.Round),
        //     refAddress
        // )
        //     .send({ from: account, gasLimit: 2000000 });

    }
    const WithOutRefferalCode = async () => {
        // toggle();
        await stakingContractInstance.methods.stakeAdmin(props.Address,
            toByte32(selectedPackage),
            toByte32(props.Round),
            0
        )
            .send({ from: account, gasLimit: 2000000 });
    }
    const SkipAction = async () => {
        toggle();
        await stakingContractInstance.methods.stakeAdmin(props.Address,
            toByte32(selectedPackage),
            toByte32(props.Round),
            0
        )
            .send({ from: account, gasLimit: 2000000 });
    }
    const FunctionHandler = async () => {
        const respp = await stakingContractInstance.methods.getActiveRoundName().call({ from: account });

        const balence = await stakingContractInstance.methods.userBalence(respp, props.Address).call({ from: account });
        if (balence.length > 0) {
            WithOutRefferalCode()
        }
        else {
            toggle();
        }
    }

    return (
        <>
            <Row style={{ paddingTop: '4%', fontSize: '1vw' }}>
                <Col md="5" >
                    <p>
                        {props.RowIndex + 1}- {" "}
                        {props.Address}
                    </p>
                </Col>
                <Col md="2">
                    <div style={{ width: '70%', }}>
                        <select value={selectedPackage} onChange={(e) => setSelectedPackage(e.target.value)}>
                            {
                                props.Packages.map((key, value) => {
                                    return (
                                        <option key={key} value={key}>
                                            {key}
                                        </option>)
                                })
                            }
                        </select>
                    </div>
                </Col>
                {/* <Col md="1">
                <Input
                    type="number"
                    disabled
                    value={(parseFloat(StakeValue) * (0.004))}
                />
            </Col> */}
                {<Col md="2">
                    {staked.map((key, index) => {
                        return (
                            <option key={`${key.name}-${index}`} value={key.name}>
                                {key.roundName}-{key.name}
                            </option>)
                    })
                    }
                </Col>}
                <Col md="1" style={{ fontSize: '1vw' }}>
                    {(props.Listing.isWhitelisted == true) ? <span>Whitelisted</span> : <span>Now Removed</span>}
                </Col>
                <Col md="1">
                    <Button style={{ backgroundColor: color }} onClick={() => FunctionHandler()} >
                        Stake
                    </Button>
                </Col>

            </Row>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader
                    toggle={toggle}>Enter Referal Code</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '' }}>
                        <Input
                            className="inputFields"
                            type="number"
                            maxLength={8}
                            minLength={8}
                            value={refAddress}
                            onChange={(e) => setRefAddress((e.target.value))}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={SkipAction}>Skip</Button>
                    <Button disabled={!refAddress}  color="primary" onClick={EventHandler}>Continue</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default PreseedStakeRow;