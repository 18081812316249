import React, { Component } from "react";
import "./styles.css";
import { Col, Container, Row } from "reactstrap";
import ProjectView from "../../Components/ProjectsView";

class HowItWorks extends Component {

    render() {
        return (
            <Container fluid className="HowitWorksContainer text-white">
                <Row className="mt-5">
                    <Col xs="12" className="text-left">
                        <h3>PolkaStarterPad – The first deflationary launchpad on the Polkadot ecosystem</h3>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs="12" md="4">
                        <Row className="justify-content-center">
                            <Col xs="11">
                            <Row className="HowItWorksTabContainer">
                                {/* <Row className="NoBackgroundTabContainer"> */}
                                    <p>The main deflationary mechanism of PolkaStarterPad include a 10% fee on all token sells and early unstaking fees of up to 25%.</p> 
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="4" className="mt-5 mt-md-0">
                        <Row className="justify-content-center">
                            <Col xs="11">
                                <Row className="HowItWorksTabContainer">
                                    <h5>Sell Fees Distribution</h5>
                                    <p>- 7.5% of the fees will be rewarded to stakers in PSPad</p>
                                    <p>- 2.5% will be burned forever</p>
                                    
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="4" className="mt-5 mt-md-0">
                        <Row className="justify-content-center">
                            <Col xs="11">
                                <Row className="HowItWorksTabContainer">
                                    <h5>Early Unstaking Fees</h5>
                                    <p>- There will be an option to unstake early which comes with an up to 25% burn fee. If you wait 8 weeks to unstake, there will be NO FEE</p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default HowItWorks;

