export const BSC_Scan = "https://testnet.bscscan.com/tx"
export const UNISWAP = "https://uniswap.org/"
export const PANCAKESWAP = "https://pancakeswap.finance/swap"
export const GOOGLE_IDO_FORM = "https://forms.gle/m3uYkcbkELqW4tETA"
export const GOOGLE_WHITELIST_FORM = "https://docs.google.com/forms/d/e/1FAIpQLScrKCtVi80ba_3JHgLWTtx4_fTXlVL7wwtcRyptS_COCDoOWA/viewform"
export const Token_Launch_Auction_Whitelist= "https://docs.google.com/forms/d/e/1FAIpQLSc5rBFBIkogQk2CMtnhO3rVbPf_q8U2TSqXwsHkRSVst7Fubg/viewform"
export const Twitter_Page = "https://twitter.com/PadPolkastarter"
export const Reddit_Page = "https://www.reddit.com/user/PolkastarterPad"
export const Medium_Page = "https://medium.com/@polkastarterpad.io"
export const Facebook_Page = "https://www.facebook.com/Polkastarter-Pad-102770095578563"
export const Telegram_Page = "https://t.me/+6f7H4ORTl4k4ZGNk"