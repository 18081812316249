import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import {
    TabContent, TabPane,
    Nav, NavItem, NavLink,
    Card, Button, CardTitle, CardText,
    Row, Col, Container,
    Progress
} from 'reactstrap';
import { PSPAD, ZUSD } from "../../Common/TokenConfig";
import { toByte32, toEth } from "../../Handlers/converters";
import { Translate } from "../../i18n/localization";
import { SingletonWeb3Instance } from "../../Web3";


interface IProps {
    Data: any

}

const AllocationTab = (props: IProps) => {
    const Web3Instance = SingletonWeb3Instance.getInstance();
    const [maxAllocation, setMaxAloaction] = useState('');
    const [claimedAllocation, setClaimedAmount] = useState('');
    const { account } = useWeb3React();

    const stakingContractInstance = Web3Instance.stakingContractInstance;

    const FetchData = async () => {
        // const Allocation = await stakingContractInstance.methods.calculateAllocation().call({ from: account, gasLimit: 999999 });
        // console.log(Allocation);
        // setMaxAloaction(Allocation);
    }
    useEffect(() => {
        FetchData();
    }, [account])
    return (
        <>
            <Row>
                <Col>
                    No
                </Col>
                <Col>
                    Allocation
                </Col>
                <Col>
                    Date
                </Col>
                <Col>
                    Claimed
                </Col>
                <Col>
                    Action
                </Col>
            </Row>
            <br />
            {/* <Row>
                <Col className="projectdetail-righttable">
                    {"1"}
                </Col>
                <Col className="projectdetail-righttable">
                    {(maxAllocation)}
                </Col>
                <Col className="projectdetail-righttable">
                    {"<DEX LISTING>"}
                </Col>
                <Col className="projectdetail-righttable">
                    {"<0.0000>"}

                </Col>
                <Col className="projectdetail-righttable">


                </Col>
            </Row> */}
            <Row>
                <hr style={{ borderTop: "2px dotted gray", marginTop: "10%" }} />
            </Row>
        </>
    )
}


export default AllocationTab;